import React, { useState, useEffect, useRef } from "react";
import "./Home.scss";
import { motion } from "framer-motion";
import carousel1 from "../../Assets/images/Home/carousel-image1.png";
import carousel2 from "../../Assets/images/Home/carousel-image2.png";
import carousel3 from "../../Assets/images/Home/carousel-image3.png";
import carousel4 from "../../Assets/images/Home/carousel-image4.png";
import carousel5 from "../../Assets/images/Home/carousel-image5.png";
import carousel6 from "../../Assets/images/Home/carousel-image6.png";
import carousel7 from "../../Assets/images/Home/carousel-image7.png";
import imactCardLayer from "../../Assets/images/Home/impact-card-layer.webp";
import hamareKarya from "../../Assets/images/Home/hamare-karya.webp";
import hamareKarya2 from "../../Assets/images/Home/hamare-karya-2.webp";
import hamareKarya3 from "../../Assets/images/Home/hamare-karya-3.webp";
import hamareKarya4 from "../../Assets/images/Home/hamare-karya-4.webp";
import focusAreas from "../../Assets/images/Home/focus-areas.webp";
import focusAreashr from "../../Assets/images/Home/focus-areas-hr.webp";
import awards from "../../Assets/images/Home/awards.webp";
import awards2 from "../../Assets/images/Home/awards2.webp";
import events1 from "../../Assets/images/Home/events-1.png";
import events2 from "../../Assets/images/Home/events-2.png";
import events3 from "../../Assets/images/Home/events-3.png";
import eventHead from "../../Assets/images/Home/events-header.webp";
import events from "../../Assets/images/Home/events.webp";
import { ParallaxProvider } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import ourReachCircle from "../../Assets/images/Home/our-reach-circle.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const carouselData = [
  {
    navigation: "आगे पढ़े →",
    img: carousel4,
    heading: "सबको स्वस्थ बनाना है संकल्प",
    text: "स्वस्थ शरीर में स्वस्थ मन वास करता है, इसलिए मधुरिमा सेवा संस्कार संस्थान ने समाज को बीमारी मुक्त बनाने के साथ स्वस्थ बनाने का संकल्प लिया है। सभी को स्वास्थ्य सेवाओं का लाभ मिले ,इसके लिए स्वास्थ्य शिविरों का आयोजन किया जाता है। इन शिविरों के जरिए समाज के हर वर्ग तक स्वास्थ्य सेवाओं की पहुंच बढ़ रही है। इसके साथ उन्हें स्वस्थ रहने के मंत्र भी दिए जाते हैं।",
  },
  {
    navigation: "आगे पढ़े →",
    img: carousel3,
    heading: "सबको स्वस्थ बनाना है संकल्प",
    text: "स्वस्थ शरीर में स्वस्थ मन वास करता है, इसलिए मधुरिमा सेवा संस्कार संस्थान ने समाज को बीमारी मुक्त बनाने के साथ स्वस्थ बनाने का संकल्प लिया है। सभी को स्वास्थ्य सेवाओं का लाभ मिले ,इसके लिए स्वास्थ्य शिविरों का आयोजन किया जाता है। इन शिविरों के जरिए समाज के हर वर्ग तक स्वास्थ्य सेवाओं की पहुंच बढ़ रही है। इसके साथ उन्हें स्वस्थ रहने के मंत्र भी दिए जाते हैं।",
  },
  {
    navigation: "आगे पढ़े →",
    img: carousel2,
    heading: "रामराज्य की तरफ बढ़ते कदम",
    text: "समाज में समन्वय हो, शांति हो, सभी की प्रगति हो और सामंजस्य हो, यही तो रामराज्य है। इस दिशा में मधुरिमा सेवा संस्कार संस्थान ने विंध्य क्षेत्र के सतना में एक अनूठी पहल की है। इसके जरिए लोगों के आपसी मतभेदों को खत्म करने के प्रयास हो रहे हैं। सभी अपने बीते दिनों की गलतियों को भुलाकर नई शुरुआत का संकल्प ले रहे हैं। यह रामराज्य की दिशा में बढ़ते कदम का प्रतीक भी है।",
  },
  {
    navigation: "आगे पढ़े →",
    img: carousel1,
    heading: "रामराज्य की तरफ बढ़ते कदम",
    text: "समाज में समन्वय हो, शांति हो, सभी की प्रगति हो और सामंजस्य हो, यही तो रामराज्य है। इस दिशा में मधुरिमा सेवा संस्कार संस्थान ने विंध्य क्षेत्र के सतना में एक अनूठी पहल की है। इसके जरिए लोगों के आपसी मतभेदों को खत्म करने के प्रयास हो रहे हैं। सभी अपने बीते दिनों की गलतियों को भुलाकर नई शुरुआत का संकल्प ले रहे हैं। यह रामराज्य की दिशा में बढ़ते कदम का प्रतीक भी है।",
  },
 
 
 
  {
    navigation: "आगे पढ़े →",
    img: carousel5,
    heading: "खेती से समृद्धि",
    text: "हमारे देश की बड़ी आय और समृद्धि का हिस्सा खेती किसानी रही है। किसान समृद्धि हो और उसके जीवन में खुशहाली आए। इसके लिए किसानों को आधुनिक खेती का प्रशिक्षण दिया जा रहा है। डिजिटल खेती को बढ़ावा दिया जा रहा है और इसके लिए किसानों को प्रशिक्षित करने का सिलसिला जारी है। साथ में उन्हें अपनी फसलों को बाजार तक पहुंचाने के तरीके भी बताए जा रहे हैं।",
  },
  {
    navigation: "आगे पढ़े →",
    img: carousel6,
    heading: "खेल",
    text: "पढ़ाई- लिखाई के साथ खेल भी जिंदगी का महत्वपूर्ण हिस्सा है । खेलों के प्रति नई पीढ़ी में लगाव बढे इसके लिए विभिन्न खेल स्पर्धाओं का आयोजन मधुरिमा सेवा संस्कार संस्थान द्वारा किया जाता है। वहीं विभिन्न संस्थाओं द्वारा आयोजित किए जाने वाले टूर्नामेंट को भी संस्था की ओर से सहयोग किया जाता है ।क्रिकेट, फुटबॉल, वॉलीबॉल जैसे टूर्नामेंट में हिस्सा लेने आने वाले खिलाड़ियों के प्रदर्शन से नई पीढ़ी में भी खेल के प्रति अभिरुचि जाग रही है। आने वाले समय में संभावना है कि सतना जिले से भी कई प्रतिभाएं राष्ट्रीय स्तर पर अपना जौहर दिखाएंगी।",
  },
  {
    navigation: "आगे पढ़े →",
    img: carousel7,
    heading: "नई पीढ़ी को संस्कारवान बनाने की मुहिम",
    text: "नई पीढ़ी रास्ते से न भटके इसके लिए उसका संस्कारवान होना जरूरी है। इसी मकसद को लेकर मधुरिमा संस्कार पाठशाला की शुरुआत की गई है। जनजातीय वर्ग के बच्चों को इस पाठशाला के जरिए महापुरुषों की जीवनी से अवगत कराया जाता है, उन्हें स्वच्छता का पाठ पढ़ाया जाता है, और योग की भी शिक्षा दी जाती है ।",
  },
];
const photoCarousel = [
  {
    heading: "दैनिक भास्कर ने दिया ‘प्राईड आफ मध्य प्रदेश -2024’ सम्मान",
    text: "मधुरिमा सेवा संस्कार संस्थान द्वारा ग्रामीण इलाकों में उपलब्ध कराई जा रही स्वास्थ्य सेवाएं और चिकित्सा के क्षेत्र में किए गए नवाचारों के लिए देश के प्रतिष्ठित समाचार पत्र दैनिक भास्कर ने ‘प्राईड ऑफ मध्य प्रदेश -2024’ के सम्मान से सम्मानित किया। भोपाल के कुशाभाऊ ठाकरे सभागार में आयोजित समारोह में मुख्यमंत्री डॉ मोहन यादव ने माधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉ स्वप्ना वर्मा को यह सम्मान प्रदान किया",
    img: awards,
  },
  {
    heading: "स्वास्थ्य मंत्री मनसुख मांडविया ने दिया ‘सुश्रुत अवार्ड -2024’",
    text: "माधुरिमा सेवा संस्कार संस्थान द्वाराविंध्य क्षेत्र के सतना जिले के ग्रामीण इलाकों में जरूरतमंदों को स्वास्थ्य सुविधाएं उपलब्ध कराई जा रही हैं वही प्रधानमंत्री श्री नरेंद्र मोदी के बीमारी मुक्त भारत अभियान को सतना में जमीन पर उतारा जा रहा है । संस्थान के सफल प्रयासों को दिल्ली में आयोजित समारोह में सम्मानित किया गया। केंद्रीय स्वास्थ्य मंत्री मनसुख मांडविया ने मधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉक्टर स्वप्ना वर्मा को ‘सुश्रुत अवार्ड -2024’ से सम्मानित किया। संस्थान के कार्यों को मध्य प्रदेश ही नहीं देश के विभिन्न हिस्सों में सराहा जा रहा है।",
    img: awards2,
  },
  {
    heading: "दैनिक भास्कर ने दिया ‘प्राईड आफ मध्य प्रदेश -2024’ सम्मान",
    text: "मधुरिमा सेवा संस्कार संस्थान द्वारा ग्रामीण इलाकों में उपलब्ध कराई जा रही स्वास्थ्य सेवाएं और चिकित्सा के क्षेत्र में किए गए नवाचारों के लिए देश के प्रतिष्ठित समाचार पत्र दैनिक भास्कर ने ‘प्राईड ऑफ मध्य प्रदेश -2024’ के सम्मान से सम्मानित किया। भोपाल के कुशाभाऊ ठाकरे सभागार में आयोजित समारोह में मुख्यमंत्री डॉ मोहन यादव ने माधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉ स्वप्ना वर्मा को यह सम्मान प्रदान किया",
    img: awards,
  },
  {
    heading: "स्वास्थ्य मंत्री मनसुख मांडविया ने दिया ‘सुश्रुत अवार्ड -2024’",
    text: "माधुरिमा सेवा संस्कार संस्थान द्वाराविंध्य क्षेत्र के सतना जिले के ग्रामीण इलाकों में जरूरतमंदों को स्वास्थ्य सुविधाएं उपलब्ध कराई जा रही हैं वही प्रधानमंत्री श्री नरेंद्र मोदी के बीमारी मुक्त भारत अभियान को सतना में जमीन पर उतारा जा रहा है । संस्थान के सफल प्रयासों को दिल्ली में आयोजित समारोह में सम्मानित किया गया। केंद्रीय स्वास्थ्य मंत्री मनसुख मांडविया ने मधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉक्टर स्वप्ना वर्मा को ‘सुश्रुत अवार्ड -2024’ से सम्मानित किया। संस्थान के कार्यों को मध्य प्रदेश ही नहीं देश के विभिन्न हिस्सों में सराहा जा रहा है।",
    img: awards2,
  },
  {
    heading: "दैनिक भास्कर ने दिया ‘प्राईड आफ मध्य प्रदेश -2024’ सम्मान",
    text: "मधुरिमा सेवा संस्कार संस्थान द्वारा ग्रामीण इलाकों में उपलब्ध कराई जा रही स्वास्थ्य सेवाएं और चिकित्सा के क्षेत्र में किए गए नवाचारों के लिए देश के प्रतिष्ठित समाचार पत्र दैनिक भास्कर ने ‘प्राईड ऑफ मध्य प्रदेश -2024’ के सम्मान से सम्मानित किया। भोपाल के कुशाभाऊ ठाकरे सभागार में आयोजित समारोह में मुख्यमंत्री डॉ मोहन यादव ने माधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉ स्वप्ना वर्मा को यह सम्मान प्रदान किया",
    img: awards,
  },
];
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const impactContainerRef = useRef(null);
  const impactContainer1Ref = useRef(null);
  const focusAreasRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = debounce(() => {
      let isImpactContainerVisible = false;
      let isImpactContainer1Visible = false;
      let isFocusAreasVisible = false;

      // Check visibility for impactContainerRef
      const impactContainer = impactContainerRef.current;
      if (impactContainer) {
        const rect = impactContainer.getBoundingClientRect();
        isImpactContainerVisible =
          rect.top < window.innerHeight && rect.bottom >= 0;
      }

      // Check visibility for impactContainer1Ref
      const impactContainer1 = impactContainer1Ref.current;
      if (impactContainer1) {
        const rect1 = impactContainer1.getBoundingClientRect();
        isImpactContainer1Visible =
          rect1.top < window.innerHeight && rect1.bottom >= 0;
      }

      // Check visibility for focusAreasRef
      const focusAreasElement = focusAreasRef.current;
      if (focusAreasElement) {
        const rect = focusAreasElement.getBoundingClientRect();
        isFocusAreasVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      }

      // Update isScrolling based on any element being visible
      setIsScrolling(
        isImpactContainerVisible ||
          isImpactContainer1Visible ||
          isFocusAreasVisible
      );
    }, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      handleScroll.cancel();
    };
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const navigateToScreen = (index) => {
    switch (index) {
      case 0:
        navigate("/health-care");
        break;
      case 1:
        navigate("/education");
        break;
      case 2:
        navigate("/establishment-of-ram-rajya");
        break;
      case 3:
        navigate("/agriculture");
        break;
      case 4:
        navigate("/sports");
        break;
      default:
        navigate("/");
    }
  };
  return (
    <div className="main-container">
      <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/" />
        <meta
          name="description"
          content="Madhurima Sewa Sanskar Foundation works for a disease-free, healthy society. We offer health camps, cultural education to the future generation, & promote farming prosperity & promote cultural harmony. Build a better community with us."
        />
        <meta
          name="keywords"
          content="Madhurima Sewa Sanskar Sansthan, Health Camps, Education, Farming, Sports, Community Development, education, community development, cultural harmony, agriculture, sports"
        />
        <title>
          Madhurima Sanskar: Health, Education & Community Development
        </title>
      </Helmet>
      <div className="carousel-container">
        <div className="carousel-slide">
          {carouselData.map((item, index) => (
            <div
              className={`carousel-item ${
                index === currentIndex ? "active" : ""
              }`}
              key={index}
            >
              <img src={item.img} className="w-100" alt={item.heading} />
              <div className="home-hero-section position-absolute top-50 start-0 translate-middle-y">
                <div className="p-3">
                  <h2>{item.heading}</h2>
                  <p>{item.text}</p>
                  <div>
                    <button
                      className="navigation-btn"
                      onClick={() => navigateToScreen(index)}
                    >
                      {item.navigation}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="carousel-dots">
          {carouselData.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
      <br />
      <br />
      <div className="card-bg">
        <div className="container" ref={impactContainerRef}>
          <div
            className={
              isScrolling ? "hamara-prabhava animate-right" : "hamara-prabhava"
            }
          >
            <h2>हमारा प्रभाव</h2>
          </div>
          <hr className="hamara-prabhava-hr" />
        </div>
        <div className="container impact-container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div
                className={
                  isScrolling ? "impact-card animate-left" : "impact-card"
                }
              >
                <h3>1,00,000+</h3>
                <div className="impact-card-text">
                  <p>लोगों का स्वास्थ्य शिविरों में स्वास्थ्य परीक्षण</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div
                className={
                  isScrolling ? "impact-card animate-top" : "impact-card"
                }
              >
                <h3>17,283+</h3>
                <div className="impact-card-text">
                  <p>
                    डिजिटल स्वास्थ्य शिविरों में पंजीकृत लोगों का कम्प्लीट
                    डिजिटल हेल्थ प्रोफाइल
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div
                className={
                  isScrolling ? "impact-card animate-right" : "impact-card"
                }
              >
                <h3>4,40,000+</h3>
                <div className="impact-card-text">
                  <p>नि:शुल्क दवाइ वितरण</p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-4 offset-md-2 mt-3">
              <div
                className={
                  isScrolling ? "impact-card animate-bottom" : "impact-card"
                }
              >
                <h3>2043</h3>
                <div className="impact-card-text">
                  <p>चश्मा वितरण</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div
                className={
                  isScrolling ? "impact-card animate-bottom" : "impact-card"
                }
              >
                <h3>106</h3>
                <div className="impact-card-text">
                  <p>नेत्र रोगियों के सफल ऑपरेशन</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container" ref={impactContainer1Ref}>
          <div
            className={
              isScrolling ? "hamara-prabhava animate-right" : "hamara-prabhava"
            }
          >
            <h2>हमारे कार्य</h2>
          </div>
          <hr className="hamara-prabhava-hr" />
        </div>

        <div className="work-section">
          <div className="work-container">
            <div className="container">
              <div className="row">
                <div
                  className={`col-md-3 mt-5 ${
                    isScrolling
                      ? "card work-card-1 animate-left"
                      : "card work-card-1"
                  }`}
                >
                  <div className="card-image" style={{ marginTop: "-103px" }}>
                    <img src={hamareKarya} alt="Card Image" />
                  </div>

                  <div className="card-content">
                    <h4 className="card-title mt-3">स्वास्थ्य देखभाल:</h4>
                    <div className="card-description">
                      <div className="back-btn">
                        <p>बीमारी मुक्त और स्वस्थ भारत</p>
                        <div>
                          <Link to="/health-care">
                            <a style={{ fontWeight: "bold" }}>आगे पढ़े→</a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mt-5">
                  <div
                    className={
                      isScrolling
                        ? "card work-card-1 animate-top"
                        : "card work-card-1"
                    }
                  >
                    <div className="card-image">
                      <img src={hamareKarya2} alt="Card Image" />
                    </div>
                    <div className="card-content">
                      <h4 className="card-title mt-3">रामराज्य स्थापना: </h4>
                      <div className="card-description">
                        <div className="back-btn">
                          <p>संस्कार से समृद्धि</p>
                          <div></div>
                          <div style={{ position: "absolute", right: 0 }}>
                            <Link to="/establishment-of-ram-rajya">
                              <a style={{ fontWeight: "bold" }}>आगे पढ़े→</a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mt-5">
                  <div
                    className={
                      isScrolling
                        ? "card work-card-1 animate-top"
                        : "card work-card-1"
                    }
                  >
                    <div className="card-image">
                      <img src={hamareKarya3} alt="Card Image" />
                    </div>
                    <div className="card-content">
                      <h4 className="card-title mt-3">शिक्षा: </h4>
                      <div className="card-description">
                        <div>
                          <div className="back-btn">
                            <p>संस्कार से समृद्धि</p>
                            <div></div>
                            <div style={{ position: "absolute", right: 0 }}>
                              <Link to="/education">
                                <a style={{ fontWeight: "bold" }}>आगे पढ़े→</a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mt-5">
                  <div
                    className={
                      isScrolling
                        ? "card work-card-1 animate-right"
                        : "card work-card-1"
                    }
                  >
                    <div className="card-image">
                      <img src={hamareKarya4} alt="Card Image" />
                    </div>
                    <div className="card-content">
                      <h4 className="card-title mt-3">कृषि: </h4>
                      <div className="card-description">
                        <div className="back-btn">
                          <p>खुशहाल किसान, समृद्ध हिंदुस्तान</p>
                          <div></div>
                          <div style={{ position: "absolute", right: 0 }}>
                            <Link to="/agriculture">
                              <a style={{ fontWeight: "bold" }}>आगे पढ़े→</a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="background-section">
        <div className="row background-row-section">
          <div className="col-md-5">
            <div className="sub-home-section position-absolute top-50 start-0 translate-middle-y">
              <div className="p-3">
                <h2>हमारी पहुंच</h2>
                <br />
                <p>
                  दूरस्थ इलाकों में रहने वाले लोगों की पहली और बड़ी जरूरत है
                  स्वास्थ्य सुविधाएं। जिनके घर के करीब स्वास्थ्य केंद्र नहीं है
                  अथवा जो लोग अस्पतालों तक पहुंचने में असमर्थ है । उन तक मधुरिमा
                  सेवा संस्कार संस्थान की पहुंच है। संस्थान द्वारा एक तरफ जहां
                  स्वास्थ्य शिविर लगाकर निःशुल्क स्वास्थ्य परीक्षण किया जाता है
                  ,परामर्श दिया जाता है और दवाइयां उपलब्ध कराई जाती हैं तो वही
                  क्लीनिक ऑन व्हील भी इन तक पहुंच रहा है । इसी कोशिश का नतीजा है
                  कि एक लाख से ज्यादा लोगों का शिविरों के जरिए स्वास्थ्य परीक्षण
                  हो चुका है, 17283 का कंपलीट डिजिटल हेल्थ प्रोफाइल तैयार किया
                  जा चुका है। 4 लाख 40000 यूनिट दवाइयां का वितरण किया गया है।
                  2043 लोगों को चश्मों का वितरण किया गया और 106 नेत्र रोगियों के
                  निःशुल्क ऑपरेशन कराए गए।
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="">
          <div className="focus-areas">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className={isScrolling ? "animate-left-to-right" : ""}>
                    <img
                      ref={focusAreasRef}
                      className="img-fluid"
                      src={focusAreas}
                      alt="Focus Areas"
                    />
                  </div>
                </div>
                <div className="col-md-4 offset-md-1 mt-4">
                  <div className="focus-areas-text">
                    <h2>हमारे फोकस क्षेत्र</h2>
                    <img
                      className="img-fluid text-center"
                      src={focusAreashr}
                      alt="Focus Areas Separator"
                    />
                    <p>
                      हमारे स्वास्थ्य शिविर के ध्यानार्थ क्षेत्रों का चयन
                      विवेकपूर्ण रूप से किया जाता है, ताकि हम समुचित सेवाएं
                      प्रदान कर सकें। हम गरीब और असहाय लोगों के बीमारियों को
                      निराकरण के लिए प्राथमिकता देते हैं। हम जीवनशैली संबंधी
                      बीमारियों के प्रतिकूल असरों के खिलाफ जागरूकता फैलाने का
                      प्रयास करते हैं। हम गर्भवती महिलाओं और बच्चों के स्वास्थ्य
                      को सुनिश्चित करने के लिए विशेष ध्यान देते हैं। हम युवाओं
                      को स्वास्थ्य और जीवनशैली में जागरूक करने के लिए प्रेरित
                      करते हैं।
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <section>
        <h1 className="text-center">
          <b style={{fontSize:39}}> पुरस्कार और सम्मान</b>
        </h1>
        <br />
        <br />
        <br />
        <div className="container carousel-container    d-flex justify-content-center">
          <br />

          <br />
          <div className="carousel-slide award-row">
            {photoCarousel.map((item, index) => (
              <div
                className={`carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
                key={index}
              >
                <img
                  src={item.img}
                  style={{}}
                  className="awards-images"
                  alt={item.heading}
                />
                <div className="awards-text">
                  <h2>{item.heading}</h2>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5" ref={impactContainerRef}>
          <div
            className={
              isScrolling ? "hamara-prabhava animate-right" : "hamara-prabhava"
            }
          >
            <h2>हाल की कार्यक्रम</h2>
          </div>
          <hr className="hamara-prabhava-hr" />
        </div>
        <img className="img-fluid events-side-img" src={events} />
        <div className="container">
          <div className="events">
            <div className="row">
              <div
                className={
                  isScrolling ? " events-box-hr animate-right" : "events-box-hr"
                }
              >
                <img className="img-fluid" src={eventHead} alt="Event Head" />
              </div>
            </div>
            <div className="events-row">
              <div className="row">
                <div className="col-md-4 mt-5">
                  <div
                    className={
                      isScrolling
                        ? "card first-event animate-left"
                        : "card first-event"
                    }
                  >
                    <div className="image-container">
                      <img className="img-fluid" src={events1} alt="Event 1" />
                      <p className="first-event-text">
                        चिकित्सा के क्षेत्र में उत्कृष्ट कार्य करने के लिए डॉ
                        स्वप्ना वर्मा का दैनिक भास्कर द्वारा भोपाल में आयोजित
                        ‘प्राईड ऑफ मध्य प्रदेश -2024’ में मध्य प्रदेश के माननीय
                        मुख्यमंत्री डॉ मोहन यादव द्वारा सम्मान।
                        <br />
                        <a className="km" href="/photo-gallery">
                          आगे पढ़े →
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-5">
                  <div
                    className={
                      isScrolling
                        ? "card second-event animate-top"
                        : "card second-event"
                    }
                  >
                    <div className="image-container">
                      <img className="img-fluid" src={events2} alt="Event 2" />
                      <p className="first-event-text">
                        मध्यप्रदेश के सतना की बेटी डॉ स्वप्ना वर्मा को दिल्ली
                        में आयोजित कार्यक्रम में केंद्रीय स्वास्थ्य मंत्री श्री
                        मनसुख मांडविया जी ने सुश्रुत अवार्ड-2024 से सम्मानित
                        किया।
                        <br />
                        <a className="km" href="/photo-gallery">
                          आगे पढ़े →
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-5">
                  <div
                    className={
                      isScrolling
                        ? "card third-event animate-right"
                        : "card third-event"
                    }
                  >
                    <div className="image-container">
                      <img className="img-fluid" src={events3} alt="Event 3" />
                      <p className="first-event-text">
                        सतना संसदीय क्षेत्र के बटोही गांव में मधुरिमा सेवा
                        संस्कार फाउंडेशन ने जनजातीय वर्ग के बच्चों को सनातनी
                        संस्कार देने के लिए में मधुरिमा संस्कार पाठशाला शुरू की
                        है।
                        <br />
                        <a className="km" href="/photo-gallery">
                          आगे पढ़े →
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
export default Home;

import React, { useEffect, useState } from "react";
import "./Press.scss";
import closemodal from "../../../Assets/images/Press/close-modal-icon.svg";
import { readService } from "../../../Services/HttpService";
import Loader from "../../../Components/LoaderScreen/LoaderScreen";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader";
import { Helmet } from "react-helmet";

const PressImages = [
  {
    engTitle: "Camp organized in Narayan Talab Seva Basti",
    engLocation: "Patrika",
    engDate: "11 August 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/aug-1.png",
    hindiDate: "11 अगस्त 2024",
    hindiTitle: "नारायण तालाब सेवा बस्ती में लगा शिविर",
    location: "पत्रिका",
  },
  {
    engTitle: "Campaign to Increase the Production of Local Products",
    engLocation: "Patrika",
    engDate: "3 August 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/aug-2.png",
    hindiDate: "3 अगस्त 2024",
    hindiTitle: "स्थानीय उत्पादनों को उत्पादन बढ़ने की मोहिम",
    location: "पत्रिका",
  },
  {
    engTitle: "Jha was an outstanding thinker and an exceptional Leader",
    engLocation: "Patrika",
    engDate: "3 August 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/aug-3.png",
    hindiDate: "3 अगस्त 2024",
    hindiTitle: "उत्कृष्ट विचारक और श्रेष्ट राजनेता थे झा",
    location: "पत्रिका",
  },
  {
    id: "1",
    engTitle: "Ration distributed in service settlements",
    engLocation: "Patrika",
    engDate: "29 June 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/pres-jun-1.png",
    hindiDate: "29 जून 2024",
    hindiTitle: "सेवा बस्तियों में बांटा राशन",
    location: "पत्रिका",
  },

  {
    id: "1",
    engTitle:
      "Dr. Swapna Verma aims for a disease-free India, a prosperous India, and a happy India",
    engLocation: "Dainik Bhaskar",
    engDate: "6 May 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/may-9.png",
    hindiDate: "6 मई 2024",
    hindiTitle:
      "सेवाभावी डॉ स्वप्ना वर्मा का लक्ष्य है- बीमारी मुक्त भारत, समृद्ध भारत और खुशहाल भारत",
    location: "दैनिक भास्कर",
  },
  {
    id: "4",
    engTitle: "The Campaign for Disease-free India continues uninterrupted",
    engLocation: "Rewanchal Roshni",
    engDate: "10 March 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press01.png",
    hindiDate: "10 मार्च 2024",
    hindiTitle: "बीमारी मुक्त भारत मुहीम लगातार जारी",
    location: "रेवांचल रोशनी",
  },
  {
    id: "5",
    engTitle: "Dr. Swapna, daughter of Satna awarded the Susrat Award",
    engLocation: "Patrika",
    engDate: "9 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press1.png",
    hindiDate: "9 जनवरी 2024",
    hindiTitle: "सतना की बेटी डॉ स्वप्ना वर्मा को सुश्रुत अवार्ड",
    location: "पत्रिका",
  },
  {
    id: "9",
    engTitle: "Students in Chitrakoot’s Batohi to be given traditional Values",
    engLocation: "Patrika",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_5.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle: "चित्रकूट के बटोही में छात्रों को दिए जाएंगे सनातनी संस्कार",
    location: "पत्रिका",
  },
  {
    id: "10",
    engTitle: "Inauguration of Madhurima Pathsala",
    engLocation: "",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_6.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle: "मधुरिमा पाठशाला का शुभारंभ",
    location: "",
  },
  {
    id: "11",
    engTitle: "Imparting Lord Rama's values to tribal children in Pathsala",
    engLocation: "",
    engDate: "2 February 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press7.png",
    hindiDate: "2 फरवरी 2024",
    hindiTitle: "पाठशाला में जनजातीय बच्चों को दे रहे राम जैसे संस्कार",
    location: "",
  },
  {
    id: "12",
    engTitle: "Lighting Diyas with tribal women",
    engLocation: "",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_8.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle: "जनजातीय महिलाओं संग दीपदान",
    location: "",
  },
  {
    id: "13",
    engTitle:
      "Performed lighting Diyas after circumambulating Kamadgiri mountain with Tribal women",
    engLocation: "",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press9.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle:
      "जनजातीय महिलाओं के साथ कामदगिरि पर्वत की परिक्रमा कर किया दीपदान",
    location: "",
  },
  {
    id: "14",
    engTitle:
      "On the prestigious occasion of the Pran Pratistha ceremony in Chitrakoot, Dr. Swapna offered diyas with Tribal women",
    engLocation: "Navbharat News",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press10.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle:
      "प्राण प्रतिष्ठा पर चित्रकूट में जनजातीय महिलाओं संग डॉ स्वप्ना ने किया दीपदान",
    location: "नवभारत न्यूज़",
  },
  {
    id: "15",
    engTitle: "The Youth participated enthusiastically and took the oath",
    engLocation: "Navbharat News",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_11.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle: "युवाओं ने बढ़ -चढ़ कर लिया हिस्सा, दिलाई गई शपथ",
    location: "नवभारत न्यूज़",
  },
  {
    id: "16",
    engTitle:
      "On Vivekananda Jayanti, the Sanatan Sankalp Yatra was conducted, and social worker Swapna Verma administered the oath",
    engLocation: "Navbharat News",
    engDate: "12 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press12.png",
    hindiDate: "12 जनवरी 2024",
    hindiTitle:
      "विवेकानंद जयंती पर निकली सनातन संकल्प यात्रा, समाजसेविका स्वप्ना वर्मा ने दिलाई शपथ",
    location: "नवभारत न्यूज़",
  },
  {
    id: "32",
    engTitle: "Campaign begins to make Vindhya disease-free",
    engLocation: "Star Samachaar",
    engDate: "10 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press28.png",
    hindiDate: "10 जनवरी 2024",
    hindiTitle: "विंध्य को बीमारी मुक्त बनाने की मुहीम का आगाज",
    location: "स्टार समाचार",
  },
  {
    id: "33",
    engTitle:
      "Inauguration of the free health camp “Susen Sanjeevani” on the birth anniversary of Bharat Ratna, former Prime Minister Shri Atal Bihar Vajpayee",
    engLocation: "",
    engDate: "10 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press29.png",
    hindiDate: "10 जनवरी 2024",
    hindiTitle:
      "भारत रत्न, पूर्व प्रधानमंत्री श्रध्येय अटल बिहारी वाजपेयी जी की जन्म जयंती पर निशुल्क सुषेण संजीवनी का शुभारंभ",
    location: "",
  },
  {
    id: "34",
    engTitle: "Medicines provided after conducting health checkups for people",
    engLocation: "",
    engDate: "26 December 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_30.png",
    hindiDate: "26 दिसंबर 2024",
    hindiTitle: "लोगों का स्वास्थ्य परिक्षण कर प्रदान की गई दवाइयां",
    location: "",
  },
  {
    id: "35",
    engTitle: "The “Sushen Sanjeevani Camp will improve the lives of patients",
    engLocation: "Vindhya Sapoot",
    engDate: "26 December 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_31.png",
    hindiDate: "26 दिसंबर 2024",
    hindiTitle: "सुषेण संजीवनी शिविर से बदलेगी मरीजों की जिंदगी",
    location: "विंध्य सपूत",
  },

  {
    id: "37",
    engTitle: "Mazagwan Nagaud did checkup of 300 people.",
    engLocation: "Patrika News Network",
    engDate: "8 January 2024",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_33.png",
    hindiDate: "8 जनवरी 2024",
    hindiTitle: "मजगवां नागौद इ ३०० लोगों का किया चेकउप ",
    location: "पत्रिका न्यूज़ नेटवर्क",
  },
  {
    id: "43",
    engTitle: "Health Reports have been given to the beneficiaries",
    engLocation: "Patrika",
    engDate: "8 October 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_39.png",
    hindiDate: "8 अक्टूबर 2023",
    hindiTitle: "लाभार्थियों को सौंपी जाँच रिपोर्ट",
    location: "पत्रिका",
  },
  {
    id: "44",
    engTitle:
      "The eyes of health camp attendees sparkled by receiving check-up reports",
    engLocation: "Satna",
    engDate: "8 October 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press40.png",
    hindiDate: "8 अक्टूबर 2023",
    hindiTitle: "जाँच रिपोर्ट पाकर चमकी शिविरार्थियों की आँखे",
    location: "सतना",
  },
  {
    id: "45",
    engTitle:
      "Madhurima Sewa Sanskar Foundation distributed Health check-up reports",
    engLocation: "Nav Swadesh",
    engDate: "8 October 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press41.png",
    hindiDate: "8 अक्टूबर 2023",
    hindiTitle: "मधुरिमा सेवा संकर फाउंडेशन ने वितरित की जाँच रिपोर्ट",
    location: "नव स्वदेश",
  },
  {
    id: "46",
    engTitle:
      "In the field of health service, Dr. Swapna Verma once again performed inspiring work",
    engLocation: "Neja",
    engDate: "3 October 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press42.png",
    hindiDate: "3 अक्टूबर 2023",
    hindiTitle:
      "स्वस्थ्य जगत में आदर्श, डॉक्टर स्वप्ना वर्मा ने फिर किया प्रेरणास्पद कार्य",
    location: "नेजा",
  },
  {
    id: "47",
    engTitle:
      "Minister of Water Resources and Flood Control, Swtantra Dev Singh visited Dr. Swapna’s health camp, boosting everyone’s morale and communicating with the patients",
    engLocation: "Satna News",
    engDate: "30 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_43.png",
    hindiDate: "30 सितम्बर 2023",
    hindiTitle:
      "जल शक्ति एवम बाढ़ नियंत्रण मंत्री स्वतंत्र देव सिंह ने डॉ स्वप्ना वर्मा के स्वास्थ्य शिविर में पहुँच कर सबका मनोबल बढ़ाया और दर्दियों से मुलाकात की ।",
    location: "सतना न्यूज़",
  },
  {
    id: "48",
    engTitle: "Digital health Profile inaugurated in Satna: Dr. Swapna Verma",
    engLocation: "Neja",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press44.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle:
      "डिजिटल हेल्थ प्रोफाइल की शुरुवात हुई सतना से : डॉ स्वप्ना वर्मा",
    location: "नेजा",
  },
  {
    id: "49",
    engTitle: "Dr. Swapna Verma became an exemplar of community service",
    engLocation: "Rewanchal Roshni",
    engDate: "28 September - 4 October 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_45.png",
    hindiDate: "28 सितम्बर 2023 से 4 अक्टूबर 2023",
    hindiTitle: "सामुदायिक सेवा की मिसाल बानी डॉक्टर स्वप्ना वर्मा",
    location: "रेवांचल रोशनी",
  },
  {
    id: "50",
    engTitle: "Digital health Profile inaugurated in Satna: Dr. Swapna Verma",
    engLocation: "Dainik Satna Star",
    engDate: "30 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press46.png",
    hindiDate: "30 सितम्बर 2023",
    hindiTitle:
      "डिजिटल हेल्थ प्रोफाइल की शुरुवात हुई सतना से : डॉ स्वप्ना वर्मा",
    location: "दैनिक सतना स्टार ",
  },
  {
    id: "51",
    engTitle: "Patients are benefitting from free health camps",
    engLocation: "Deshbandhu",
    engDate: "28 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_47.png",
    hindiDate: "28 सितम्बर 2023",
    hindiTitle: "मरीजों को मिल रहा निशुल्क स्वस्थ शिविर का लाभ",
    location: "देशबंधु",
  },
  {
    id: "52",
    engTitle: "Health camp conducted by Madhurima Sewa Sanskar",
    engLocation: "Nav Swadesh",
    engDate: "9 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_48.png",
    hindiDate: "9 अगस्त 2023",
    hindiTitle: "मधुरिमा सेवा संस्कार द्वारा लगाया गया स्वस्थ शिविर",
    location: "नव स्वदेश",
  },
  {
    id: "54",
    engTitle: "Dr. Swapna sets an example for social service",
    engLocation: "Dainik Satna Star",
    engDate: "28 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_50.png",
    hindiDate: "28 सितम्बर 2023",
    hindiTitle: "सामुदायिक सेवा की मिसाल डॉ स्वप्ना वर्मा",
    location: "दैनिक सतना स्टार",
  },
  {
    id: "55",
    engTitle: "In Satna, health checkups are conducted in camps",
    engLocation: "Patrika",
    engDate: "15 September2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_51.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "सतना में शिविर कराई स्वस्थ्य जाँच",
    location: "पत्रिका",
  },
  {
    id: "56",
    engTitle: "Women are touched by heart by the social service of Dr. Swapana",
    engLocation: "Neja",
    engDate: "15 September2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press52.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "डॉक्टर स्वप्ना का सेवा भाव देख भावुक हुई महिलाएं",
    location: "नेजा",
  },
  {
    id: "57",
    engTitle:
      "Dr. Swapna garners increased trust from the natives of Satna for her health campaign",
    engLocation: "Sanwaddata",
    engDate: "3 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_53.png",
    hindiDate: "3 सितम्बर 2023",
    hindiTitle:
      "डॉक्टर स्वप्ना वर्मा के स्वास्थ्य मुहिम पर सतना वासियों का बढ़ा विश्वास",
    location: "संवाददाता",
  },
  {
    id: "58",
    engTitle: "The first joy is a healthy Body: Dr. Swapna Verma",
    engLocation: "Nav Swadesh",
    engDate: "03 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_54.png",
    hindiDate: "03 सितम्बर 2023",
    hindiTitle: "पहला सुख निरोगी काया: डॉ स्वपना वर्मा",
    location: "नव स्वदेश",
  },
  {
    id: "59",
    engTitle: "The “Wheel Rural India” project is moving forward",
    engLocation: "MPJS News",
    engDate: "03 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_55.png",
    hindiDate: "3 सितम्बर 2023",
    hindiTitle: "आगे बढ़ रहा ' व्हील रूरल इंडिया' का संकल्प  ",
    location: "एमपिजेऐस न्यूज़",
  },
  {
    id: "60",
    engTitle: "Health camps in Tikuria Tola",
    engLocation: "Patrika",
    engDate: "24 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_56.png",
    hindiDate: "24 सितम्बर 2023",
    hindiTitle: "टिकुरिया टोला में स्वास्थ्य शिविर",
    location: "पत्रिका",
  },
  {
    id: "61",
    engTitle:
      "Dr. Swapna Verma enhanced the confidence of locals and built their trust by conducting a Health campaign",
    engLocation: "Neja",
    engDate: "24 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press58.png",
    hindiDate: "24 सितम्बर 2023",
    hindiTitle:
      "डॉ स्वपना वर्मा के स्वास्थ्य मुहिम पर सतना वासियों का बढ़ा विश्वास",
    location: "नेजा",
  },
  {
    id: "62",
    engTitle:
      "Dr. Swapna Verma enhanced the confidence of locals and built their trust by conducting a Health campaign",
    engLocation: "Navbharat News",
    engDate: "24 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press59.png",
    hindiDate: "24 सितम्बर 2023",
    hindiTitle:
      "डॉ स्वपना वर्मा के स्वास्थ्य मुहिम पर सतना वासियों का बढ़ा विश्वास",
    location: "नवभारत न्यूज़",
  },
  {
    id: "63",
    engTitle: "Patients are benefitting from free health check-up camps",
    engLocation: "Jagran",
    engDate: "24 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press60.png",
    hindiDate: "24 सितम्बर 2023",
    hindiTitle: "निशुल्क स्वस्थ्य शिविर में मरीजों को मिल रहा लाभ",
    location: "जागरण",
  },
  {
    id: "64",
    engTitle: "Testing of 7 thousand people in eight days",
    engLocation: "Nav Swadesh",
    engDate: "24 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press61.png",
    hindiDate: "24 सितम्बर 2023",
    hindiTitle: "8 दिन में सात हजार लोगों का परिक्षण",
    location: "नव स्वदेश",
  },
  {
    id: "65",
    engTitle:
      "Important role of healthy society in building a strong nation: Dr. Swapna Verma",
    engLocation: "Rewanchal Roshni",
    engDate: "23 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_63.png",
    hindiDate: "23 सितम्बर 2023",
    hindiTitle:
      "मजबूत राष्ट निर्माण में स्वस्थ समाज की अहम् भागीदारी: डॉ स्वप्ना वर्मा",
    location: "रेवांचल रोशनी",
  },
  {
    id: "66",
    engTitle:
      "Dr. Swapna Verma explained potential ways to improve blood pressure",
    engLocation: "Sanwaddata",
    engDate: "23 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_64.png",
    hindiDate: "23 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना वर्मा ने बताए रक्तचाप बेहतर करने के उपाय",
    location: "संवाददाता",
  },
  {
    id: "67",
    engTitle: "Civilians are benefitting from the free health camps",
    engLocation: "Sanwaddata",
    engDate: "23 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_65.png",
    hindiDate: "23 सितम्बर 2023",
    hindiTitle: "स्वस्थ्य शिविरों का लाभ ले रहे आमजन",
    location: "संवाददाता",
  },
  {
    id: "68",
    engTitle:
      "The inauguration of free health checkups at Maa Durga Mandir, Adarsh Nagar, and Sai Mandir Dhawari on the eighth day of Camps",
    engLocation: "Neja",
    engDate: "23 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press66.png",
    hindiDate: "23 सितम्बर 2023",
    hindiTitle:
      "माँ दुर्गा मंदिर आदर्श नगर एवं साई मंदिर धवारी में निशुल्क जाँच शिविर के आठवें दिन का शुभारंभ",
    location: "नेजा",
  },
  {
    id: "69",
    engTitle: "Examination of patients under the leadership of Dr. Swapna",
    engLocation: "Nav Swadesh",
    engDate: "23 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press67.png",
    hindiDate: "23 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना के नेतृत्व में मरोजों का परिक्षण",
    location: "नव स्वदेश",
  },
  {
    id: "70",
    engTitle:
      "Dr. Swapna Verma explained potential ways to improve blood pressure",
    engLocation: "Amarkirti",
    engDate: "23 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press68.png",
    hindiDate: "23 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना ने बताये रक्तचाप बेहतर करने के उपाय",
    location: "अमरकीर्ति",
  },
  {
    id: "71",
    engTitle:
      "Dr. Swapna Verma explained potential ways to improve blood pressure",
    engLocation: "Deshbandhu",
    engDate: "22 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_69.png",
    hindiDate: "22 सितम्बर 2023",
    hindiTitle: "शिविरों में बताए रक्तचाप बेहतर करने के उपाय",
    location: "देशबंधु",
  },
  {
    id: "72",
    engTitle:
      "In the camp, Dr. Swapna Verma told ways to improve blood pressure.",
    engLocation: "Patrika",
    engDate: "22 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_70.png",
    hindiDate: "22 सितम्बर 2023",
    hindiTitle: "शिविर में डॉ स्वप्ना वर्मा ने बताए रक्तचाप बेहतर करने के उपाय",
    location: "पत्रिका",
  },
  {
    id: "73",
    engTitle: "Dr. Swapna Verma told ways to improve blood pressure",
    engLocation: "Nav Swadesh",
    engDate: "22 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press71.png",
    hindiDate: "22 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना वर्मा ने बताए रक्तचाप बेहतर करने के उपाय",
    location: "नव स्वदेश",
  },
  {
    id: "74",
    engTitle:
      "Healthy society has the biggest role in building a strong nation.",
    engLocation: "Dainik Jagran ",
    engDate: "21 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press72.png",
    hindiDate: "21 सितम्बर 2023",
    hindiTitle: "मजबूत राष्ट्र निर्माण में स्वस्थ समाज की सबसे बड़ी भागेदारी",
    location: "दैनिक जागरण",
  },
  {
    id: "75",
    engTitle:
      "A healthy society is important in building a strong nation: Dr. Swapna Verma",
    engLocation: "Neja",
    engDate: "21 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press73.png",
    hindiDate: "21 सितम्बर 2023",
    hindiTitle:
      "मजबूत राष्ट्र निर्माण में स्वस्थ समाज की सबसे बड़ी भागेदारी : डॉ स्वप्ना वर्मा",
    location: "नेजा",
  },
  {
    id: "76",
    engTitle: "Fifth day health camp started by worshiping Gajanan",
    engLocation: "Saar-Samachar",
    engDate: "21 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_74.png",
    hindiDate: "21 सितम्बर 2023",
    hindiTitle: "गजानन की पूजा कर शुरू हुआ पांचवा दिन का स्वास्थ्य शिविर ",
    location: "सार-समाचार",
  },
  {
    id: "77",
    engTitle:
      "Dr. Swapna overwhelmed after receiving blessings from the elderly",
    engLocation: "Raj News Network ",
    engDate: "20 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press75.png",
    hindiDate: "20 सितम्बर 2023",
    hindiTitle: "बुजुर्ग से आशीर्वाद पाकर डॉ स्वप्ना अभिभूत",
    location: "राज न्यूज़ नेटवर्क",
  },
  {
    id: "78",
    engTitle:
      "Growing under the shade of compassion, social service is flourishing",
    engLocation: "Neja",
    engDate: "20 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press76.png",
    hindiDate: "20 सितम्बर 2023",
    hindiTitle: "ममता की छांव में बढ़ रही हे सेवा की बगिया",
    location: "नेजा",
  },
  {
    id: "79",
    engTitle:
      "In Dr. Swapna’s health camp, people are benefitting from health treatment",
    engLocation: "Navbharat News",
    engDate: "18 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press77.png",
    hindiDate: "18 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना के स्वास्थ्य शिविर में लोगों का हो रहा इलाज",
    location: "नवभारत न्यूज़",
  },
  {
    id: "80",
    engTitle: "Near Vayankatesh Temple: Free health check-up on the fourth day",
    engLocation: "Patrika",
    engDate: "18 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_78.png",
    hindiDate: "18 सितम्बर 2023",
    hindiTitle:
      "व्यंकटेश मंदिर के समीप शिविर: चौथे दिन निःशुल्क स्वास्थ्य परिक्षण",
    location: "पत्रिका",
  },
  {
    id: "81",
    engTitle: "Dr. Swapna wiped the tears of old age women by her lap",
    engLocation: "Nav Swadesh",
    engDate: "18 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press79.png",
    hindiDate: "18 सितम्बर 2023",
    hindiTitle:
      "भावुक हुई बूढी माँ तो अपने आंचल से डॉ स्वप्ना ने पोछे उनके आंसू",
    location: "नव स्वदेश",
  },
  {
    id: "87",
    engTitle: "Dr. Swapna resolves for disease-free India",
    engLocation: "",
    engDate: "18 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_85.png",
    hindiDate: "18 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना का बीमारी मुक्त भारत का संकल्प",
    location: "",
  },
  {
    id: "88",
    engTitle: "A health camp was set up in Nayi Basti and Kothi Road",
    engLocation: "Nav Swadesh",
    engDate: "19 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press86.png",
    hindiDate: "19 सितम्बर 2023",
    hindiTitle: "नयी बस्ती व कोठी रोड में लगाया गया स्वास्थ्य शिविर",
    location: "नव स्वदेश",
  },
  {
    id: "89",
    engTitle:
      "A health camp was held in Dakshin Pateri and Kripalpur, where medicine distribution took place",
    engLocation: "Patrika",
    engDate: "17 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_87.png",
    hindiDate: "17 सितम्बर 2023",
    hindiTitle:
      "दक्षिण पतेरी व कृपालपुर में लगा स्वास्थ्य शिविर, दवा का किया वितरण",
    location: "पत्रिका",
  },
  {
    id: "90",
    engTitle:
      "Dr. Swapna Verma’s free health check-up campaign is receiving overwhelming support",
    engLocation: "Raj News Network",
    engDate: "17 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_88.png",
    hindiDate: "17 सितम्बर 2023",
    hindiTitle:
      "डॉ स्वप्ना वर्मा के निःशुल्क स्वास्थ जाँच अभियान को मिल रहा भरपूर समर्थन",
    location: "राज न्यूज़ नेटवर्क",
  },
  {
    id: "91",
    engTitle:
      "Dr. Swapna provides free medical treatment to patients suffering from Viral Fever",
    engLocation: "Nav Swadesh",
    engDate: "17 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press89.png",
    hindiDate: "17 सितम्बर 2023",
    hindiTitle: "वाइरल फीवर मरीजों का डॉ स्वप्ना कर रही निःशुल्क इलाज",
    location: "नव स्वदेश",
  },
  {
    id: "92",
    engTitle: "Free Health check-up campaign started",
    engLocation: "",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_90.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "निशुल्क हेल्थ चेकअप अभियान शुरू",
    location: "",
  },
  {
    id: "93",
    engTitle:
      "Grand inauguration of free health check-up campaign door-to-door",
    engLocation: "Navbharat News",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_91.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "घर- घर निःशुल्क स्वास्थ्य जाँच अभियान का भव्य शुभारंभ",
    location: "नवभारत न्यूज़",
  },
  {
    id: "94",
    engTitle:
      "Grand inauguration of the Healthy Satna Campaign: Free Health check-up campaign door-to-door",
    engLocation: "Raj News Network",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_92.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle:
      "स्वस्थ सतना घर- घर निःशुल्क स्वास्थ्य जाँच अभियान का भव्य शुभारंभ",
    location: "राज न्यूज़ नेटवर्क",
  },
  {
    id: "95",
    engTitle:
      "Beginning of Dr. Swapna’s free health checkup campaign: 32 camps in 16 Days",
    engLocation: "",
    engDate: "16 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_93.png",
    hindiDate: "16 सितम्बर 2023",
    hindiTitle:
      "१६ दिन और ३२ शिविर… डॉ स्वप्ना वर्मा के निःशुल्क स्वास्थ्य जाँच अभियान की शुरुवात",
    location: "",
  },
  {
    id: "96",
    engTitle:
      "The team of doctors led by Dr. Swapna arrived in Satna for a disease-free India",
    engLocation: "Deshbandhu",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_94.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle:
      "बीमारी मुक्त भारत के लिए डॉ स्वप्ना के नेतृत्व में सतना पहुंची डॉक्टर्स की टीम",
    location: "देशबंधु ",
  },
  {
    id: "97",
    engTitle:
      "Dr. Swapna’s team is visiting door-to-door, providing information about the health camp",
    engLocation: "Deshbandhu",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press95.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना की टीम पहुंच रही घर-घर ,शिविर की दे रही जानकारी",
    location: "",
  },
  {
    id: "98",
    engTitle:
      "Dr. Swapna’s team of doctors, under her leadership, has reached Satna, completing preparation for a free health check-up camps",
    engLocation: "",
    engDate: "16 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press96.png",
    hindiDate: "16 सितम्बर 2023",
    hindiTitle:
      "डॉ स्वप्ना के नेतृतव में सतना पहुंची डॉक्टर्स की टीम निःशुल्क स्वस्थ जाँच शिविर की तैयारी पूरी",
    location: "",
  },
  {
    id: "99",
    engTitle:
      "A team of doctors has reached under the leadership of Dr. Swapna",
    engLocation: "Navbharat News",
    engDate: "16 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press97.png",
    hindiDate: "16 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना के नेतृतव में पहुंची डॉक्टर्स की टीम",
    location: "नवभारत न्यूज़",
  },
  {
    id: "100",
    engTitle:
      "A team of doctors has reached under the leadership of Dr. Swapna",
    engLocation: "Nav Swadesh",
    engDate: "16 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press98.png",
    hindiDate: "16 सितम्बर 2023",
    hindiTitle: "डॉ स्वप्ना के नेतृतव के सतना पहुंची डॉक्टर्स की टीम",
    location: "नव स्वदेश",
  },
  {
    id: "101",
    engTitle:
      "For the first time, Madhurima Foundation’s team of doctors will provide free health care at home: Dr. Swapna",
    engLocation: "Nav Swadesh",
    engDate: "16 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press99.png",
    hindiDate: "16 सितम्बर 2023",
    hindiTitle:
      "पहली बार मधुरिमा फाउंडेशन की डॉक्टर्स टीम देगी घर पहुंच निःशुल्क स्वास्थ्य सुविधा : डॉ स्वप्ना",
    location: "नव स्वदेश",
  },
  {
    id: "102",
    engTitle:
      "Swasth Satna Maha Abhiyan will provide free health checkup at every home.",
    engLocation: "Navbharat News",
    engDate: "13 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_100.png",
    hindiDate: "13 सितम्बर 2023",
    hindiTitle: "स्वस्थ सतना महाभियान में घर घर होगी निःशुल्क स्वास्थ्य जाँच",
    location: "नवभारत न्यूज़",
  },
  {
    id: "103",
    engTitle:
      "Dream of Swapna is to build a Healthy Satna by conducting 32 health camps in 16 Days",
    engLocation: "Deshbandhu",
    engDate: "16 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press101.png",
    hindiDate: "16 सितम्बर 2023",
    hindiTitle:
      "स्वप्ना का स्वप्ना, बने स्वस्थ सतना, १६ दिन में लगेंगे ३२ स्वास्थ्य शिविर",
    location: "देशबंधु",
  },
  {
    id: "104",
    engTitle:
      "Disease-Free India Campaign: Conducting 32 Health Camps in 16 Days",
    engLocation: "Patrika",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_102.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "बीमारी मुक्त भारत अभियान ; १६ दिन में लगेंगे ३२ शिविर",
    location: "पत्रिका",
  },
  {
    id: "105",
    engTitle: "Door-to-door free Health checkups: 32 Health Camps in 16 Days",
    engLocation: "",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_103.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "घर-घर होगी निःशुल्क स्वास्थ जाँच, १६ दिन में लगेंगे ३२ शिविर",
    location: "",
  },
  {
    id: "106",
    engTitle:
      "The Coordinator of the Disease-Free India Campaign provided Information",
    engLocation: "Jagran",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press104.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "बीमारी मुक्त भारत अभियान की संचालिका ने दी जानकारी",
    location: "जागरण",
  },
  {
    id: "107",
    engTitle:
      "Free Health camps will be conducted door-to-door for disease identification",
    engLocation: "Bhaskar News",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_105.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "घर घर निःशुल्क स्वास्थ्य शिविर में की जाएगी रोगों की पहचान",
    location: "भास्कर न्यूज़",
  },
  {
    id: "108",
    engTitle: "Health camps will be organized by 15 September",
    engLocation: "PMJS News",
    engDate: "15 September 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_106.png",
    hindiDate: "15 सितम्बर 2023",
    hindiTitle: "शहर में १५ से आयोजित होंगे स्वास्थ्य शिविर",
    location: "एमपीजीएस न्यूज़",
  },
  {
    id: "114",
    engTitle: "Inauguration of Clinic on Wheels",
    engLocation: "Patrika Plus",
    engDate: "24 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_112.png",
    hindiDate: "24 अगस्त 2023",
    hindiTitle: "क्लीनिक ऑन व्हील्स का शुभारंभ",
    location: "पत्रिका प्लस",
  },
  {
    id: "115",
    engTitle: "Grand Inauguration of Clinic on Wheels",
    engLocation: "Star Samachar",
    engDate: "24 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_113.png",
    hindiDate: "24 अगस्त 2023",
    hindiTitle: "क्लीनिक ऑन व्हील्स का उट्घाटन",
    location: "स्टार समाचार",
  },
  {
    id: "116",
    engTitle: "Satna got the gift of clinic on wheels",
    engLocation: "Bhaskar News",
    engDate: "24 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_114.png",
    hindiDate: "24 अगस्त 2023",
    hindiTitle: "सतना को मिली क्लीनिक ऑन व्हील्स की सौगात",
    location: "भास्कर न्यूज़",
  },
  {
    id: "117",
    engTitle:
      "Disease-free Satna’s dream of prosperous wedding will come true: Dr. Swapna Verma",
    engLocation: "Dainik Bhaskar",
    engDate: "24 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press115.png",
    hindiDate: "24 अगस्त 2023",
    hindiTitle:
      "रोग मुक्त सतना से साकार होगा समृद्ध भारत का सपना : डॉ स्वप्ना वर्मा",
    location: "दैनिक भास्कर",
  },
  {
    id: "118",
    engTitle: "Children’s faces lit up after receiving school bags",
    engLocation: "Dainik Ujjwal",
    engDate: "18 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_116.png",
    hindiDate: "18 अगस्त 2023",
    hindiTitle: "स्कूल बैग पाकर खिले बच्चों के चहरे",
    location: "दैनिक उज्वल",
  },
  {
    id: "119",
    engTitle: "Children’s faces lit up after receiving school bags",
    engLocation: "Nav Swadesh",
    engDate: "18 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_117.png",
    hindiDate: "18 अगस्त 2023",
    hindiTitle: "स्कूल बैग पाकर खिले बच्चों के चहरे",
    location: "नव स्वदेश",
  },
  {
    id: "122",
    engTitle:
      "The disease-free rural India Project team arrived, and enthusiasm was seen among the villagers of Barha Bhatia.",
    engLocation: "",
    engDate: "9 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press120.png",
    hindiDate: "9 अगस्त 2023",
    hindiTitle:
      "बीमारी मुक्त ग्रामीण भारत की प्रकल्प की टीम पहुंची, बरहा भाटिया के ग्रामजनों में दिखा उत्साह",
    location: "",
  },
  {
    id: "123",
    engTitle:
      "The team of ‘Disease Free Rural India’ project reached village Barha Bhatia",
    engLocation: "Nav Swadesh",
    engDate: "9 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press121.png",
    hindiDate: "9 अगस्त 2023",
    hindiTitle:
      "‘बीमारी मुक्त ग्रामीण भारत’प्रकल्प की टीम पहुंची ग्राम बरहा भाटिया",
    location: "नव स्वदेश",
  },
  {
    id: "124",
    engTitle: "Contract between Medical College and IMS",
    engLocation: "Patrika",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_122.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle: "मेडिकल कॉलेज व आइएमएस में अनुबंध",
    location: "पत्रिका",
  },
  {
    id: "125",
    engTitle:
      "Village will be free from diseases, campaign will be run, contract will be signed with medical college",
    engLocation: "Dainik Bhaskar",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_123.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle:
      "बीमारी से मुक्त होंगे गांव चलेगा अभियान मेडिकल कॉलेज से हुआ अनुबंध",
    location: "दैनिक भास्कर",
  },
  {
    id: "126",
    engTitle: "Chitrakoot will mark the beginning of disease-free India",
    engLocation: "Deshbandhu",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press124.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle: "चित्रकूट से होगी बीमारी मुक्त भारत की शुरुआत",
    location: "देशबंधु",
  },
  {
    id: "127",
    engTitle:
      "Chief Minister’s historic welcome and felicitation will take place in Rewa: Rajendra Shukla",
    engLocation: "Navbharat News",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_125.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle:
      "मुख्यमंत्री का ऐतिहासिक स्वागत और वंदन रीवा में होगा : राजेंद्र शुक्ल",
    location: "नवभारत न्यूज़",
  },
  {
    id: "128",
    engTitle: "There will be a Mahakumbh for the sisters in Rewa",
    engLocation: "Jagran",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press126.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle: "रीवा में होगा लाड़ली बहनों का महाकुंभ",
    location: "जागरण",
  },
  {
    id: "129",
    engTitle:
      "The people of Vindhya will benefit from free healthcare services through the Disease-Free India Project",
    engLocation: "Star Samachar",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_127.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle:
      "बीमारी मुक्त भारत प्रकल्प से विंध्य की जनता को मिलेगा निःशुल्क स्वास्थ्य सुविधा का लाभ",
    location: "स्टार समाचार",
  },
  {
    id: "130",
    engTitle:
      "Efforts for investigation and diagnosis to make India disease free",
    engLocation: "",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press128.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle: "भारत बीमारी मुक्त बनाने को लेकर जाँच एवं निदान के प्रयास",
    location: "",
  },
  {
    id: "131",
    engTitle:
      "The people of Vindhya will get the benefit of free health care from the disease-free India project.",
    engLocation: "",
    engDate: "5 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press129.png",
    hindiDate: "5 अगस्त 2023",
    hindiTitle:
      "बीमारी मुक्त भारत प्रकल्प से विंध्य की जनता को मिलेगा निःशुल्क स्वास्थ सुरक्षा का लाभ",
    location: "",
  },
  {
    id: "132",
    engTitle: "Disease free India launched in the district",
    engLocation: "Nav Swadesh",
    engDate: "8 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press130.png",
    hindiDate: "8 अगस्त 2023",
    hindiTitle: "जिले में बीमारी मुक्त ग्रामीण भारत का शुभारंभ",
    location: "नव स्वदेश",
  },
  {
    id: "133",
    engTitle:
      "Training completed in Medical College, Rewa for starting disease-free rural India project",
    engLocation: "",
    engDate: "8 August 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press131.png",
    hindiDate: "8 अगस्त 2023",
    hindiTitle:
      "बीमारी मुक्त ग्रामीण भारत प्रकल्प के आरंभ हेतु चिकित्सा महाविद्यालय, रीवा में प्रशिक्षण सम्पन्न",
    location: "",
  },
  {
    id: "137",
    engTitle:
      "The 5th batch departs for cataract under the Heel Rural India Mission",
    engLocation: "Nav Swadesh",
    engDate: "28 July 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press135.png",
    hindiDate: "28 जुलाई 2023",
    hindiTitle:
      "हील रूरल इंडिया मिशन के तहत मोतियाबिंद के ऑपरेशन के लिए ५ वा जत्था रवाना",
    location: "नव स्वदेश",
  },
  {
    id: "138",
    engTitle:
      "The 5th batch departs for cataract under the Heel Rural India Mission",
    engLocation: "Nav Swadesh",
    engDate: "28 July 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press136.png",
    hindiDate: "28 जुलाई 2023",
    hindiTitle:
      "हील रूरल इंडिया मिशन के तहत मोतियाबिंद के ऑपरेशन के लिए ५ वा जत्था रवाना",
    location: "नव स्वदेश",
  },
  {
    id: "143",
    engTitle: "Inauguration of a two-day grand fair",
    engLocation: "NavBharat News",
    engDate: "22 July 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press141.png",
    hindiDate: "22 जुलाई 2023",
    hindiTitle: "दो दिवसीय स्वर्णिम मेला का शुभारंभ",
    location: "नव भारत",
  },
  {
    id: "144",
    engTitle: "spectacles will be distributed",
    engLocation: "Dainik Bhaskar",
    engDate: "9 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_142.png",
    hindiDate: "9 मई 2023",
    hindiTitle: "वितरित किया जायेगा नजर का चश्मा",
    location: "दैनिक भास्कर",
  },
  {
    id: "145",
    engTitle: "On the first day of the free camp, 2,000 eye patients arrived",
    engLocation: "Bhaskar News",
    engDate: "9 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press143.png",
    hindiDate: "9 मई 2023",
    hindiTitle: "निःशुल्क शिविर में पहले दिन पहुंचे २ हजार नेत्र रोगी",
    location: "भास्कर न्यूज़",
  },
  {
    id: "146",
    engTitle: "Investigation of more than two thousand people",
    engLocation: "Patrika News Network",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_144.png",
    hindiDate: "10 मई 2023",
    hindiTitle: "नेत्र परिक्षण शिविर, दो हजार से ज्यादा लोगों की जाँच",
    location: "पत्रिका न्यूज़ नेटवर्क",
  },
  {
    id: "147",
    engTitle: "Closing ceremony of the three-day free eye camp",
    engLocation: "Neja",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press145.png",
    hindiDate: "10 मई 2023",
    hindiTitle: "तीन दिवसीय निःशुल्क नेत्र शिविर का समापन",
    location: "नेजा",
  },
  {
    id: "148",
    engTitle: "Disease-Free India Campaign is our Goal, said Dr. Swapna",
    engLocation: "MPJS News",
    engDate: "8 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press146.png",
    hindiDate: "8 मई 2023",
    hindiTitle: "बीमारी मुक्त भारत अभियान हमारा लक्ष्य: डॉ स्वप्ना",
    location: "एमपीजेएस न्यूज़",
  },
  {
    id: "149",
    engTitle: "Three-day free eye camp at Saraswati Vidya Peeth from the 8th",
    engLocation: "",
    engDate: "7 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_147.png",
    hindiDate: "7 मई 2023",
    hindiTitle: "तीन दिवसीय निःशुल्क नेत्र शिविर ८ से सरस्वती विद्यापीठ में",
    location: "",
  },
  {
    id: "150",
    engTitle: "Eye Clinic on Wheels starts soon",
    engLocation: "Nav Swadesh",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_148.png",
    hindiDate: "10 मई 2023",
    hindiTitle: "आई क्लीनिक ऑन व्हील्स शीघ्र ही होगा प्रारंभ",
    location: "नव स्वदेश",
  },
  {
    id: "151",
    engTitle: "On the first day of the free camp, 2,000 eye patients arrived",
    engLocation: "Bhaskar News",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press149.png",
    hindiDate: "10 मई 2023",
    hindiTitle: "निःशुल्क शिविर में पहले दिन पहुंचे २ हजार नेत्र रोगी",
    location: "भास्कर न्यूज़",
  },
  {
    id: "152",
    engTitle: "Cataract surgery mission achieves 100% success rate",
    engLocation: "",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press_150.png",
    hindiDate: "10 मई 2023",
    hindiTitle: "कैटरेक्ट ऑपरेशन के लिए दुस्त्र जत्था 100 प्रतिशत सफल",
    location: "",
  },
  {
    id: "153",
    engTitle:
      "First Time free eye screening for 6,000 eye patients will be conducted",
    engLocation: "",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press151.png",
    hindiDate: "10 मई 2023",
    hindiTitle: "पहली बार होगा ६ हजार नेत्ररोगियों का निःशुल्क परिक्षण",
    location: "",
  },
  {
    id: "154",
    engTitle:
      "The Grand Eye Camp was organized through Madhurima Sewa Sanskar, and its implementation was initiated",
    engLocation: "Neja",
    engDate: "10 May 2023",
    url: "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/press-notes/pressImages/press152.png",
    hindiDate: "10 मई 2023",
    hindiTitle:
      "मधुरिमा सेवा संस्कार के द्वारा बृहद नेत्र शिविर का किया गया था आयोजन जिसके क्रियान्वयन का क्रम हुआ प्रारंभ",
    location: "नेजा",
  },
];

export default function Press() {
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [activeParagraph, setActiveParagraph] = useState(0);

  useEffect(() => {
    getPressDataList();
  }, []);

  useEffect(() => {
    getEventDataList();
  }, []);
  const getPressDataList = async () => {
    let response = await readService("swapna/press");
    if (response) {
      setSections(response.data);
    }
    console.log("Data:", response.data);
  };
  const handleCardClick = (image) => {
    setSelectedImage(image);
    openModal();
  };
  const handleTextClick = (index) => {
    setActiveParagraph(index);
  };
  const getEventDataList = async () => {
    try {
      setLoading(true);
      let response = await readService("swapna/Press");
      if (response) {
        setSections(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const contentAndImages = [];
  const translatedText = "डिजिटल प्रिंट";
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/news-clip"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.madhurimasanskar.org/news-clip"
        />
        <meta
          name="description"
          content="Read about the Madhurima Sewa Sanskar Foundation's latest news and updates. Learn about their mission, programs, and how to get volunteered in supporting their social initiatives"
        />
        <meta
          name="keywords"
          content="Madhurima Sewa Sanskar Foundation, non-profit, Bengaluru, India, charitable foundation, social services"
        />
        <title>Madhurima Sewa Sanskar Foundation: Latest News</title>
      </Helmet>
      <div className="sub-main-container">
        <TitleHeader title={translatedText} />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="content-event">
          {loading && <Loader />}
          <div className="event-bg">
            <div className="container section-body">
              <div className="row d-flex justify-content-center">
                <br />
                <br />
                <br />
                {PressImages &&
                  PressImages.map((section) => (
                    <div
                      key={section.pressId}
                      onClick={() => handleCardClick(section.url)}
                      className="col-md-4"
                    >
                      <div>
                        <div>
                          <div>
                            <img src={section.url} className="press-image" />
                          </div>
                        </div>
                        <div className="pres-text">
                          <p>{section.location}</p>
                          <p>{section.hindiDate}</p>
                        </div>
                        <div className="d-flex justify-content-start">
                          <br />
                          <br />
                          <br />
                          <br />
                          <div>
                            <h2 className="press-title">
                              {section.hindiTitle}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <section id="press-section2">
          <div className="">
            <div className="">
              <div className="card-group">
                {contentAndImages.map((item, index) => (
                  <div key={index}>
                    <div
                      className=""
                      onClick={() => handleTextClick(index)}
                      id="cardimg"
                    >
                      <br />
                      <div className=" d-flex flex-wrap " id="press-card-img">
                        <div className="container-fluid pt-5 pb-5">
                          <div className="row   ">
                            <h5 className="image-header-text ">
                              <span className="">{item.content}</span>
                            </h5>
                          </div>
                        </div>
                        {item.images.map((image, imgIndex) => (
                          <>
                            <div
                              key={imgIndex}
                              className=" col-md-3 image-with-text text-center mb-3"
                              onClick={() => handleCardClick(image)}
                            >
                              <div className="image-card">
                                <img
                                  className="card-img-top rounded-0 img-fluid"
                                  src={image}
                                  alt={`Event ${index + 1} Image ${
                                    imgIndex + 1
                                  }`}
                                />
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={`modal modal-overlay ${showModal ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{
                  display: showModal ? "block" : "none",
                  zIndex: "9999",
                }}
              >
                <div class="row align-items-end ">
                  <div class="col c-btn">
                    <img
                      src={closemodal}
                      alt="colse modal"
                      style={{ position: "absolute", top: 0, right: 2 }}
                      onClick={closeModal}
                    />
                  </div>
                </div>
                <div style={{ padding: "56px" }}>
                  <div>
                    <div className="modal-body">
                      <img
                        class="card-img-top rounded-0 img-fluid"
                        src={selectedImage}
                        alt="Card image cap"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import "./Agriculture.scss";
import healthCareImage from "../../../Assets/images/OurJob/Agriculture/agriculture-bg.png";
import rightAngle from "../../../Assets/images/OurJob/HealthCare/right-angle.svg";
import agriculture1 from "../../../Assets/images/OurJob/Agriculture/agriculture1.png";
import agriculture2 from "../../../Assets/images/OurJob/Agriculture/agriculture2.webp";
import agriculture3 from "../../../Assets/images/OurJob/Agriculture/agriculture3.webp";
import agriculture4 from "../../../Assets/images/OurJob/Agriculture/agriculture4.webp";
import agriculture5 from "../../../Assets/images/OurJob/Agriculture/agriculture5.webp";
import agriculture6 from "../../../Assets/images/OurJob/Agriculture/agriculture6.webp";
import agriculture7 from "../../../Assets/images/OurJob/Agriculture/agriculture7.webp";
import agriculture8 from "../../../Assets/images/OurJob/Agriculture/agriculture8.webp";
import { Helmet } from "react-helmet";
function Agriculture() {
  const weWorkRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const scrollToSection = (ref) => {
    if (ref.current) {
      const headerHeight = 130;
      const offsetTop = ref.current.offsetTop - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/agriculture"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
      <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/agriculture" />
        <meta
          name="description"
          content="Empowering farmers economically with geranium oil farming! Dr. Swapna's foundation offers training, resources for sustainable agriculture in India."
        />
        <meta
          name="keywords"
          content="geranium oil farming, digital agriculture, agriculture training, farmer income increase"
        />
        <title>Dr. Swapna: Empowering Farmers with Geranium Oil Farming</title>
      </Helmet>
      <div>
        <div className="position-relative">
          <img src={healthCareImage} className="w-100" alt="Health Care" />
          <div className="hero-section position-absolute top-50 start-0 translate-middle-y">
            <div className="p-3">
              <h1>कृषि</h1>
              <p>
                हमारे देश की बड़ी आय और समृद्धि का हिस्सा खेती किसानी रही है।
                किसान समृद्धि हो और उसके जीवन में खुशहाली आए। इसके लिए किसानों
                को आधुनिक खेती का प्रशिक्षण दिया जा रहा है। डिजिटल खेती को
                बढ़ावा दिया जा रहा है और इसके लिए किसानों को प्रशिक्षित करने का
                सिलसिला जारी है। साथ में उन्हें अपनी फसलों को बाजार तक पहुंचाने
                के तरीके भी बताए जा रहे हैं।
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row agriculture-left-sidebar">
            <div className="col-md-3 mt-5 mobile-view-section">
              <aside>
                <ul className="subtabs">
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(weWorkRef)}
                  >
                    हम कैसे काम करते हैं
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(whatWeDoRef)}
                  >
                    उद्योग
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-md-9 mt-5">
              <section id="weWork" ref={weWorkRef}>
                <div className="agriculture-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम कैसे काम करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="agriculture-text mt-3">
                  <p>
                    किसान भारत की आर्थिक समृद्धि और खुशहाली की रीढ़ है क्योंकि
                    लगभग 70 फ़ीसदी आबादी इस काम से जुड़ी हुई है। देश की समृद्धि
                    के लिए जरूरी है कि किसान खुशहाल हो। यह तभी संभव है जब किसान
                    की आय बढे, फसल का उचित दाम मिले । ऐसा होने पर किसानों के
                    जीवन में खुशहाली आयेगी और किसान देश की समृद्धि का कारण
                    बनेगा। इसके लिए मधुरिमा सेवा संस्कार फाउंडेशन लगातार
                    प्रयासरत है। किसानों को सशक्त बनाने के लिए दलहन, तिलहन और
                    औषधीय पौधों जैसी वैकल्पिक फसलों को बढ़ावा देकर किसानों की
                    जीवन शैली में बदलाव लाया जा रहा है। डिजिटल एग्रीकल्चर को
                    बढ़ावा दिया जा रहा है । किसानों को उन्नत किस्म के बीज उपलब्ध
                    कराकर उनकी पैदावार में इजाफा करने के प्रयास हो रहे हैं।
                    किसानों को विशेषज्ञों के जरिए बेहतर मार्गदर्शन दिलाया जा रहा
                    है। आधुनिक खेती अपनाएं और आयभी बढ़े इसके लिए जिरेनियम
                    एसेंशियल ऑयल फार्मिंग के बारे में भी किसानों को अवगत कराया
                    जा रहा है।
                  </p>
                  <img src={agriculture1} className="w-100" />

                  <div className="mt-5">
                    <h2>
                      कृषि को विज्ञान से जोड़कर ही खुलेगा अन्नदाता किसानों की
                      तरक्की का रास्ता
                    </h2>
                    <div className="row mt-5">
                      <div className="col-md-7 ">
                        <p>
                          प्रधानमंत्री नरेंद्र मोदी के नेतृत्व में भारत का झंडा
                          आज चांद पर लहरा रहा है। विज्ञान और तकनीक आज हमारे देश
                          के हर सेक्टर में तेजी से अपने पांव पसार रही है। ऐसे
                          में, कृषिप्रधान देश की अर्थव्यवस्था में अति महत्वपूर्ण
                          योगदान देने वाले कृषि क्षेत्र की तरक्की को भी आधुनिक
                          तकनीक से तेज रफ्तार देने की सख्त जरूरत है। इसके महत्व
                          को समझते हुए मोदी सरकार कृषि क्षेत्र के आधुनिकीकरण के
                          लिए तेजी से परिवर्तनकारी कदम उठा रही है। केंद्र सरकार
                          जहां एक ओर किसानों की आर्थिक स्थिति को मजबूत कर रही
                          है, वहीं पॉलीहाउस, हाइड्रोपोनिक तकनीक व कृषि में ड्रोन
                          के इस्तेमाल से खेती को और आसान, बेहतर कृषिप्रधान और
                          समृद्धशाली बनाने की कोशिशें भी व्यापक स्तर पर की जा
                          रही हैं।
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img src={agriculture2} className="w-100" />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-md-7 ">
                        <h2>डिजिटल एग्रीकल्चर को बढ़ावा देने की पहल</h2>
                        <p>
                          मोदी सरकार के इन महत्वपूर्ण कदमों से भारत का कृषि
                          सेक्टर भी अब इसी बूते पर आगे बढ़ता नजर आ रहा है। कृषि
                          और कृषकों के कल्याण के इस अभियान में सक्रिय योगदान
                          देने के लिए डॉ स्वप्ना ने अपनी संस्था के माध्यम से
                          डिजिटल एग्रीकल्चर को बढ़ाने के लिए कदम बढ़ाया है। इसके
                          तहत किसानों को विभिन्न कार्यक्रमों का आयोजन करके
                          डिजिटल तकनीक की मदद से खेती करने के बारे में जानकारी
                          दी जा रही है। किसानों के इस ज्ञानवर्धन के साथ ही देश
                          के अन्नदाता किसानों को आर्थिक मदद पहुंचाने की दृष्टि
                          से शत-प्रतिशत ‘बाय बैक गारंटी’ देने की कोशिशें भी
                          संस्था द्वारा की जा रही हैं।
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img src={agriculture3} className="w-100" />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-md-7 ">
                        <h2>
                          जिरेनियम की खेती से किसानों की तकदीर बदलने का इरादा
                        </h2>
                        <p>
                          बेहतर आमदनी के लिए मधुरिमा सेवा संस्कार फाउंडेशन के
                          कार्यक्रमों के ज़रिये डॉ. स्वप्ना किसानों को जिरेनियम
                          एसेंशियल ऑयल फार्मिंग के बारे में जानकारी दे रही हैं।
                          जिरेनियम की खेती किसानों को आर्थिक रूप से सक्षम बनाती
                          है। जिरेनियम ऑयल प्राकृतिक उपचार और अरोमाथेरेपी यानी
                          सुगंध चिकित्सा में काम आता है और बाजार में इसकी काफी
                          मांग होने के साथ ही इसकी कीमत भी काफी अच्छी मिलती है।
                          इसलिए जेरेनियम की खेती से किसानों की तकदीर बदल सकती
                          है। बता दें कि फिलहाल भारत में जिरेनियम की खपत 200 टन
                          से अधिक है, जबकि इसका उत्पादन भारत में 10 टन से भी कम
                          होता है।
                          <br />
                          <br />
                          इसे देखते हुए डॉ स्वप्ना ने जिरेनियम की खेती करने में
                          सिर्फ किसानों की मदद ही नहीं कि बल्कि इसके एसेंशियल
                          ऑयल को बनाने के लिए भी व्यवस्था की गई है। इससे किसानों
                          को आर्थिक मदद भी मिली है। जिरेनियम की खेती के लिए
                          तुमकुर जिले के पावगड़ा तालुका में जोरो-शोरो से काम चल
                          रहा है। इतना ही नहीं कृषि वैज्ञानिकों और विशेषज्ञों की
                          मदद से किसानों की फसलों को और बेहतर बनाने का काम चल
                          रहा है।
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img src={agriculture4} className="w-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br />

              <section id="weWork" ref={whatWeDoRef}>
                <div className="agriculture-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>उद्योग</h2>
                </div>
                <hr className="hr-line" />
                <div className="agriculture-text mt-3">
                  <div className="row mt-5">
                    <div className="col-md-7 ">
                      <h2>
                        जेरेनियम प्रोसेसिंग उद्योग से मिल रही किसानों की आय
                        बढ़ाने में मदद
                      </h2>
                      <p>
                        भारत में उन्नत खेती के जरिये किसानों की आय दुगनी करने के
                        मोदी सरकार के लक्ष्य को हांसिल करने में योगदान दे रही
                        डॉ. स्वप्ना, उनके मार्गदर्शन में संचालित जेरेनियम के
                        प्रसंस्करण उद्योग का प्रकल्प काफी कामियाब रहा है। पहले
                        चरण में इसके पायलट प्रोजेक्ट की सफलता के बाद जिरेनियम
                        फार्मिंग की ओर किसानों का आकर्षण बढ़ा है। इससे किसानों की
                        आमदनी में कई गुना बढ़ोतरी होने की उम्मीद जगी है। साथ ही
                        परंपरागत कृषि की जगह उन्नत कृषि को प्रोत्साहन मिलने से
                        किसानों के जीवन में खुशहाली भी आ रही है।
                      </p>
                    </div>
                    <div className="col-md-5">
                      <img src={agriculture5} className="w-100" />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-7 ">
                      <h2>फाउंडेशन कर रहा किसानों का भरपूर सहयोग</h2>
                      <p>
                        जेरेनियम फार्मिंग के लिए तुमकुर जिले के पावगड़ा तालुका के
                        किसानों को डॉ स्वप्ना और आईएमएस फाउंडेशन की ओर से भरपूर
                        सहयोग दिया जा रहा है। डॉ.स्वप्ना वर्मा की ओर से उन्हें
                        कृषि वैज्ञानिकों और विशेषज्ञों की मदद एवं खेती के लिए
                        जरूरी सभी प्रकार की मदद की जा रही हैं। इससे वहां के
                        किसान काफी खुश हैं और अब वह व्यापक पैमाने पर जेरेनियम की
                        खेती करने लगे हैं।
                      </p>
                    </div>
                    <div className="col-md-5">
                      <img src={agriculture6} className="w-100" />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-7 ">
                      <h2>औद्योगिक फसल ने खोला तरक्‍की का रास्‍ता</h2>
                      <p>
                        जेरेनियम जैसी नकदी फसल उगाने से लेकर उसके प्रसंस्करण तक
                        के कार्य-कलाप में किसानों की मदद की जा रही है। इस तरह
                        उद्योग आधारित फसल उगाने में मिल रही मदद ने किसानों की
                        जिंदगी में तरक्की का एक नया रास्ता खोल दिया है। आईएमएस
                        फाउंडेशन की तरफ से जेरेनियम फार्मिंग से लेकर इसके
                        प्रसंस्करण उद्योग को स्थापित करने में व्यापक स्तर पर
                        किसानों को सहयोग प्रदान किया गया है।
                      </p>
                    </div>
                    <div className="col-md-5">
                      <img src={agriculture7} className="w-100" />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-7 ">
                      <h2>एसेंशियल ऑयल की मार्केटिंग की भी व्यवस्था</h2>
                      <p>
                        जेरेनियम की खेती और स्थानीय किसानों द्वारा उत्पादित
                        जेरेनियम का अर्क यानी एसेंशियल ऑयल निकाल कर उसके
                        मार्केटिंग की व्यवस्था भी संस्था की ओर से की गई है।
                        जेरेनियम की खेती से लेकर उसके तेल की बिक्री और वितरण तक
                        में मिल रही इस मदद से किसानों के लिए अपने भविष्य को
                        संवारना मुमकिन हो रहा है।
                      </p>
                    </div>
                    <div className="col-md-5">
                      <img src={agriculture8} className="w-100" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agriculture;

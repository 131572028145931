import React, { useState, useRef, useEffect } from "react";
import "./Establishment.scss";
import healthCareImage from "../../../Assets/images/OurJob/Establishment/establishment-bg.png";
import rightAngle from "../../../Assets/images/OurJob/HealthCare/right-angle.svg";
import waves from "../../../Assets/images/OurJob/Education/waves-png.webp";
import carousel1 from "../../../Assets/images/OurJob/Establishment/carousel-1.webp";
import carousel2 from "../../../Assets/images/OurJob/Establishment/carousel-2.webp";
import carousel3 from "../../../Assets/images/OurJob/Establishment/carousel-3.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import closeBtn from "../../../Assets/images/cross.png";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
function Establishment() {
  const weWorkRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const clinicOnWheelsRef = useRef(null);
  const impact = useRef(null);
  const stories = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const scrollToSection = (ref) => {
    if (ref.current) {
      const headerHeight = 130;
      const offsetTop = ref.current.offsetTop - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const images = [
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v8.mp4",
      imageUrl: carousel1,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v9.mp4",
      imageUrl: carousel2,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v10.mp4",
      imageUrl: carousel3,
    },
  ];
  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/establishment-of-ram-rajya"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.madhurimasanskar.org/establishment-of-ram-rajya"
        />
        <meta
          name="description"
          content="End family conflict & promote peace in your community. Madhurima Sewa Sansthan helps to build healthy relationships & build a stronger society inspired by Ram Rajya."
        />
        <meta
          name="keywords"
          content="Ram Rajya, dispute resolution, family conflict resolution, social harmony, Madhurima Sewa Sansthan"
        />
        <title>Build Harmony, Resolve Family Disputes - Madhurima Sewa</title>
      </Helmet>
      <div>
        <div className="position-relative">
          <img src={healthCareImage} className="w-100" alt="Health Care" />
          <div className="hero-section position-absolute top-50 start-0 translate-middle-y">
            <div className="p-3">
              <h1>रामराज्य स्थापना</h1>
              <p>
                समाज में समन्वय हो, शांति हो, सभी की प्रगति हो और सामंजस्य हो,
                यही तो रामराज्य है। इस दिशा में मधुरिमा सेवा संस्कार संस्थान ने
                विंध्य क्षेत्र के सतना में एक अनूठी पहल की है। इसके जरिए लोगों
                के आपसी मतभेदों को खत्म करने के प्रयास हो रहे हैं। सभी अपने बीते
                दिनों की गलतियों को भुलाकर नई शुरुआत का संकल्प ले रहे हैं। यह
                रामराज्य की दिशा में बढ़ते कदम का प्रतीक भी है।
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row left-sidebar">
            <div className="col-md-3 mt-5">
              <aside>
                <ul className="subtabs">
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(weWorkRef)}
                  >
                    हम कैसे काम करते हैं
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(whatWeDoRef)}
                  >
                    हम क्या करते हैं
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(clinicOnWheelsRef)}
                  >
                    कहानियाँ जो हमें प्रेरणा देती हैं
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-md-9 mt-5">
              <section id="weWork" ref={weWorkRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम कैसे काम करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="we-work-text mt-3">
                  <p>
                    समाज में शांति, समृद्धि, आपसी रिश्तों में प्रगाढ़ता, अपराध
                    मुक्त समाज और आपसी सामंजस्य ही राम राज्य है। इस दिशा में
                    मधुरिमा सेवा संस्कार फाउंडेशन ने बड़ी पहल की है। इसके जरिए
                    आपसी विवाद और कटुता को खत्म कर रिश्तो में मिठास घोली जा रही
                    है। संस्थान के सदस्य गांव के लोगों से संवाद करते हैं और जिन
                    परिवारों में या समाज के लोगों में विवाद है अथवा दूरियां बढ़
                    चुकी हैं जो कड़वाहट का रूप ले रही है। उन्हें परामर्श दिया
                    जाता है कि आपस में मिलकर रहने में ही सबकी भलाई है। बड़ी
                    संख्या में लोगों पर इस कोशिश का असर भी हुआ है परिणाम स्वरूप
                    सास-बहू ,भाभी- नंद, चाचा-भतीजे, भाई-भाई और पड़ोसियों के बीच
                    बढी दूरी खत्म हो गई है। सब मिलकर परिवार, गांव और समाज की
                    तस्वीर बदलने के लिए आगे बढ़ रहे हैं। यह बदलाव उस दौर में
                    शुरू हुआ है जब अयोध्या में रामलला की प्राण प्रतिष्ठा हुई।
                    500 साल के संघर्ष के बाद अयोध्या में रामलला के आने के बाद
                    राम राज्य की स्थापना का सपना पूरा हो यही सभी की आकांक्षा है
                    और सतना के ग्रामीण इलाकों में इसके लिए बड़ी पहल हुई है।
                  </p>
                </div>
              </section>
              <section id="weWork" ref={whatWeDoRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम क्या करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="we-work-text mt-3">
                  <div className="waves-icon">
                    <img src={waves} style={{ width: "100px" }} />
                  </div>
                  <br />
                  <p>
                    समाज में खुशहाली हो, शांति हो, आपसी मतभेद न हो, सभी एक दूसरे
                    का सम्मान करें और मदद के लिए तैयार रहें ।यही तो रामराज्य है।
                    मधुरिमा सेवा संस्कार संस्थान ने ग्रामीण इलाकों में लोगों के
                    बीच बढ़ी दूरी और बढे मतभेद को खत्म करने के लिए अनोखी मुहिम
                    शुरू की। इसके तहत संस्थान के लोग उन परिवारों से या लोगों से
                    मिलते हैं जिनके अपनों से रिश्ते बिगड़ गए हैं। दोनों को
                    समझाया जाता है और उनके ग़िलों शिकवों को भी खत्म किया जाता
                    है। उसका नतीजा यह हो रहा है कि वर्षों से जिन लोगों के
                    रिश्तों में कड़वाहट आ गई थी ,वह अब मिठास में बदल रही है।
                    अलग-अलग रहने वाले परिवार अब एकजुट हो रहे हैं। इन कोशिशें ने
                    जहां समाज में लोगों के बीच बढ़ी दूरी को खत्म कर नजदीकियां
                    बढ़ाने का काम किया है तो वही समाज में और गांव में भी शांति
                    की नई बयार बह रही है।
                  </p>
                </div>
              </section>

              <section id="stories" ref={clinicOnWheelsRef}>
                <div className="we-work mt-4">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>कहानियाँ जो हमें प्रेरणा देती हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="carousel-container">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="carousel-slide">
                        <img
                          src={image.imageUrl}
                          style={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => openModal(image)}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={{
                    content: {
                      width: "100%",
                      margin: "auto",
                      border: "none",
                      background: "none",
                      padding: 0,
                      inset: 0,
                    },
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.85)",
                    },
                  }}
                >
                  <div>
                    <button onClick={closeModal} className="close-btn">
                      <img src={closeBtn} />
                    </button>
                  </div>

                  {currentImage && (
                    <video controls autoPlay className="display-video">
                      <source src={currentImage.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Modal>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Establishment;

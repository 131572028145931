import React from "react";
import "./TitleHeader.scss";

const TitleHeader = ({ title}) => (
  <>
    <div id="header-title">
      <div className="container">
        <h1 className="title-header">{title}</h1>
      </div>
    </div>
  </>
);

export default TitleHeader;

import React, { useState, useRef, useEffect } from "react";
import "./Education.scss";
import healthCareImage from "../../../Assets/images/OurJob/Education/education-bg.png";
import rightAngle from "../../../Assets/images/OurJob/HealthCare/right-angle.svg";
import waves from "../../../Assets/images/OurJob/Education/waves-png.webp";
import carousel1 from "../../../Assets/images/OurJob/Education/carousel-1.webp";
import carousel2 from "../../../Assets/images/OurJob/Education/carousel-2.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import closeBtn from "../../../Assets/images/cross.png";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
function Education() {
  const weWorkRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const clinicOnWheelsRef = useRef(null);
  const impact = useRef(null);
  const stories = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const images = [
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v7.mp4",
      imageUrl: carousel1,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v6.mp4",
      imageUrl: carousel2,
    },
  ];
  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollToRef = (ref) => {
    if (ref.current) {
      const headerHeight = 130; 
      const offsetTop = ref.current.offsetTop - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/education"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
    <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/education" />
        <meta name="description" content="Madhurima Sanskar Pathshala cultivates values, hygiene & knowledge in tribal children. We teach biographies of inspiring heroes, yoga & cleanliness. Empower the next generation of India!" />
        <meta name="keywords" content="Madhurima Sanskar Pathshala, Tribal education, Value education, Sanskar education, Cleanliness education, Yoga education, Empowering children" />
        <title>Madhurima Sanskar Pathshala: Educating & Empowering Tribal Children</title>
      </Helmet>
      <div>
        <div className="position-relative">
          <img src={healthCareImage} className="w-100" alt="Health Care" />
          <div className="hero-section-right-side position-absolute top-50 end-0 translate-middle-y">
            <div className="p-3">
              <h1>शिक्षा सक्षमता समृद्धि</h1>
              <p>
                नई पीढ़ी रास्ते से न भटके इसके लिए उसका संस्कारवान होना जरूरी
                है। इसी मकसद को लेकर मधुरिमा संस्कार पाठशाला की शुरुआत की गई है।
                जनजातीय वर्ग के बच्चों को इस पाठशाला के जरिए महापुरुषों की जीवनी
                से अवगत कराया जाता है, उन्हें स्वच्छता का पाठ पढ़ाया जाता है, और
                योग की भी शिक्षा दी जाती है ।
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row left-sidebar">
            <div className="col-md-3 mt-5">
            <aside>
                <ul className="subtabs">
                  <li className="subtab" onClick={() => scrollToRef(weWorkRef)}>
                    हम कैसे काम करते हैं
                  </li>
                  <li className="subtab" onClick={() => scrollToRef(whatWeDoRef)}>
                    हम क्या करते हैं
                  </li>
                  <li className="subtab" onClick={() => scrollToRef(stories)}>
                    कहानियाँ जो हमें प्रेरणा देती हैं
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-md-9 mt-5">
            <section id="weWork" ref={weWorkRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम कैसे काम करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="we-work-text mt-3">
                  <p className="text-justify">
                    संस्कार बनाते हैं समाज और देश की समृद्धि का रास्ता। जब भी
                    संस्कारों को अहमियत दी गई तो इतिहास रचा गया है और जब
                    संस्कारों को दरकिनार किया गया समाज गर्त में गया है। इसी बात
                    को ध्यान में रखकर मधुरिमा सेवा संस्कार फाउंडेशन ने संस्कार
                    पाठशाला की शुरुआत की है। जनजातीय क्षेत्र में शुरू की गई इस
                    पाठशाला में आने वाले बच्चों को जहां जरूरत की सामग्री उपलब्ध
                    कराई जा रही है, वहीं उन्हें हम सब के आदर्श राम, कृष्ण ,
                    महात्मा गांधी, डॉ भीमराव अंबेडकर ,दीनदयाल उपाध्याय , श्यामा
                    प्रसाद मुखर्जी, सुभाष चंद्र बोस, भगत सिंह जैसे नायकों की
                    कहानियों से अवगत कराया जा रहा है। इसके साथ ही बच्चों को
                    स्वच्छता का पाठ पढ़ाया जा रहा है और उन्हें योग की भी शिक्षा
                    दी जा रही है। इस पाठशाला में पढ़ने आने वाले बच्चों में बदलाव
                    भी नजर आने लगा है। अब बच्चे जहां खाना खाने से पहले हाथ धोने
                    को प्राथमिकता देने लगे हैं तो वही अपने आसपास के क्षेत्र की
                    साफ सफाई पर भी उनका ध्यान है। वे संस्कृत के श्लोक बोलने लगे
                    हैं और योगाभ्यास भी करते हैं।
                  </p>
                </div>
              </section>
              <section id="whatWeDo" ref={whatWeDoRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम क्या करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="we-work-text mt-3">
                  <div className="waves-icon">
                    <img src={waves} style={{ width: "100px" }} />
                  </div>
                  <br/>
                  <p>
                    देश और समाज की सबसे बड़ी ताकत है नई पीढ़ी। इसी के कंधों पर
                    है बड़ी जिम्मेदारी। यह पीढ़ी सही रास्ते पर हो तो राष्ट्र का
                    गौरव नई ऊंचाइयों पर पहुंचता है। मधुरिमा सेवा संस्कार संस्थान
                    ने नई पीढ़ी को संस्कारवान बनाने के लिए मधुरिमा संस्कार
                    पाठशाला की शुरुआत की है। इस पाठशाला के जरिए बच्चों को जहां
                    हमारे आदर्श महापुरुषों का पाठ पढ़ाया जा रहा है वहीं उन्हें
                    स्वच्छता से लेकर योग की शिक्षा भी दी जा रही है। इस तरह नई
                    पीढ़ी को संस्कार दिए जा रहे हैं और उन्हें बताया जा रहा है कि
                    हमारा देश कितना गौरवशाली देश है।
                  </p>
                </div>
              </section>

              <section id="stories" ref={stories}>
                <div className="we-work mt-4">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>कहानियाँ जो हमें प्रेरणा देती हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="carousel-container">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="carousel-slide">
                        <img
                          src={image.imageUrl}
                          style={{
                            width: "100%",
                            height: "300px",
                            cursor: "pointer",
                          }}
                          onClick={() => openModal(image)}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={{
                    content: {
                      width: "100%",
                      margin: "auto",
                      border: "none",
                      background: "none",
                      padding: 0,
                      inset: 0,
                    },
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.85)",
                    },
                  }}
                >
                  <div>
                    <button onClick={closeModal} className="close-btn">
                      <img src={closeBtn} />
                    </button>
                  </div>

                  {currentImage && (
                    <video controls autoPlay className="display-video">
                      <source src={currentImage.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Modal>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;

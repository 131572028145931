import React, { useEffect, useState } from "react";
import "./Contact.scss";
import location from "../../Assets/images/Contact/location.svg";
import emailIcon from "../../Assets/images/Contact/email.svg";
import telePhone from "../../Assets/images/Contact/telephone.svg";
import newBg from "../../Assets/images/Contact/new-bg.webp";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};

    if (!name) {
      validationErrors.name = "Name is required";
    }

    if (!email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      validationErrors.email = "Email is invalid";
    }

    if (!phone) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(phone)) {
      validationErrors.phone = "Phone number must be 10 digits";
    }

    if (!description) {
      validationErrors.description = "Description is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    const formData = {
      name,
      email,
      phone,
      description,
    };

    console.log("formData---->", formData);

    setIsSubmitted(true);

    setName("");
    setEmail("");
    setPhone("");
    setDescription("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container contact-bg d-flex flex-column min-vh-100">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.madhurimasanskar.org/contact-us"
        />
        <meta
          name="description"
          content="Connect with Madhurima Sewa Sanskar Foundation! Get in touch to learn more about our social services, volunteer opportunities!"
        />
        <meta
          name="keywords"
          content="Madhurima Sewa Sanskar Foundation, contact, social services, NGO, help, support, volunteer"
        />
        <title>
          Contact Madhurima Sewa Sanskar Foundation | Help & Support
        </title>
      </Helmet>
      <div id="contact-main-section" className="flex-grow-1">
        <div className="">
          <section>
            <br />
            <br />
            <br />
            <br />
            <div className="container contact-left-section">
              <div className="contact-border">
                <div>
                  <h1>संपर्क</h1>
                  <p>संपर्क करें और हमें बताएं कि हम कैसे मदद कर सकते हैं?</p>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div className="col-md-6">
                      {isSubmitted && (
                        <div className="alert alert-success" role="alert">
                          Your message has been sent successfully!
                          <button
                            type="button"
                            className="close"
                            onClick={() => setIsSubmitted(false)}
                          >
                            <span>&times;</span>
                          </button>
                        </div>
                      )}

                      <div className="form-group">
                        <label htmlFor="leftInput">नाम</label>
                        <input
                          type="text"
                          className="form-control"
                          id="leftInput"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && (
                          <small className="text-danger">{errors.name}</small>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="rightInput">ईमेल</label>
                        <input
                          type="email"
                          className="form-control"
                          id="rightInput"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email && (
                          <small className="text-danger">{errors.email}</small>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="leftInput">फ़ोन</label>
                        <input
                          type="tel"
                          className="form-control"
                          id="leftInput"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        {errors.phone && (
                          <small className="text-danger">{errors.phone}</small>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="rightInput">संदेश</label>
                        <textarea
                          className="form-control"
                          id="rightInput"
                          style={{ height: 100 }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        {errors.description && (
                          <small className="text-danger">
                            {errors.description}
                          </small>
                        )}
                      </div>

                      <div>
                        <button
                          className="submit-btn mt-4 w-100"
                          // onClick={handleSubmit}
                        >
                          संदेश भेजें
                        </button>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div className=" d-flex justify-content-center">
                        <img src={newBg} className="new-bg-image" />
                      </div>
                      <div className="left-icons">
                        <div className="left-side-images">
                          <img src={location} />
                          <div className="mt-2" style={{ width: "240px" }}>
                            <p>Mukhtiyarganj, satna, Madhyapradesh</p>
                          </div>
                        </div>
                        <div className="left-side-images">
                          <img src={telePhone} />
                          <div className="mt-2">
                            <p> 080 2679 1234</p>
                          </div>
                        </div>
                        <div className="left-side-images">
                          <img src={emailIcon} />
                          <div className="mt-2">
                            <p>contact@madhurimasanskar.org</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;

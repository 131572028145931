import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from "./Screens/Home/Home";
import About from "./Screens/About/About";
import 'bootstrap/dist/css/bootstrap.min.css';
import HealthCare from "./Screens/OurJob/HealthCare/HealthCare";
import Education from "./Screens/OurJob/Education/Education";
import Establishment from "./Screens/OurJob/Establishment/Establishment";
import Agriculture from "./Screens/OurJob/Agriculture/Agriculture";
import Games from "./Screens/OurJob/Games/Games";
import PhotoGallery from "./Screens/MediaGallery/PhotoGallery/PhotoGallery";
import VideoGallery from "./Screens/MediaGallery/VideoGallery/VideoGallery";
import Press from "./Screens/News/Press/Press";
import DigitalPrint from "./Screens/News/DigitalPrint/DigitalPrint";
import Contact from "./Screens/Contact/Contact";
import Footer from "./Components/Footer/Footer";


const App = () => {
 return (
  <Router>
  <Header />
  <main className="main-content">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/health-care" element={<HealthCare />}/>
      <Route path="/education" element={<Education/>} />
      <Route path="/establishment-of-ram-rajya" element={<Establishment/>} />
      <Route path="/agriculture" element={<Agriculture/>} />
      <Route path="/sports" element={<Games/>} />
      <Route path="/photo-gallery" element={<PhotoGallery/>} />
      <Route path="/video" element={<VideoGallery/>} />
      <Route path="/news-clip" element={<Press/>} />
      <Route path="/digital-print" element={<DigitalPrint/>} />
      <Route path="/contact-us" element={<Contact/>} />
    </Routes>
  </main>
  <Footer />
</Router>
 );
};

export default App;
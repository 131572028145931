import React, { useState, useRef, useEffect } from "react";
import "./HealthCare.scss";
import healthCareImage from "../../../Assets/images/OurJob/HealthCare/health-care.png";
import rightAngle from "../../../Assets/images/OurJob/HealthCare/right-angle.svg";
import busIcon from "../../../Assets/images/OurJob/HealthCare/bus-icon.webp";
import ClinicWheels from "../../../Assets/images/OurJob/HealthCare/Clinic-wheels-icon.webp";
import carousel1 from "../../../Assets/images/OurJob/HealthCare/carousel-1.webp";
import carousel2 from "../../../Assets/images/OurJob/HealthCare/carousel-2.webp";
import carousel3 from "../../../Assets/images/OurJob/HealthCare/carousel-3.webp";
import carousel4 from "../../../Assets/images/OurJob/HealthCare/carousel-4.webp";
import carousel5 from "../../../Assets/images/OurJob/HealthCare/carousel-5.webp";
import closeBtn from "../../../Assets/images/cross.png";
import waves from "../../../Assets/images/OurJob/Education/waves-png.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
function HealthCare() {
  const weWorkRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const clinicOnWheelsRef = useRef(null);
  const impact = useRef(null);
  const stories = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const scrollToSection = (ref) => {
    if (ref.current) {
      const headerHeight = 130;
      const offsetTop = ref.current.offsetTop - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const images = [
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v1.mp4",
      imageUrl: carousel1,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v2.mp4",
      imageUrl: carousel2,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v3.mp4",
      imageUrl: carousel3,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v4.mp4",
      imageUrl: carousel4,
    },
    {
      videoUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v5.mp4",
      imageUrl: carousel5,
    },
  ];
  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleThumbnailClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/health-care"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/health-care" />
        <meta
          name="description"
          content="Madhurima Sewa Sansthan fights for a disease-free India with free medical camps, mobile clinics & health awareness programs. We've treated 63,480+ patients."
        />
        <meta
          name="keywords"
          content="Free Medical Camps, Mobile Clinics India, Disease Free India, Health Awareness Programs, Madhurima Sewa Sansthan, Clinic on Wheels, Mass health screening, Medical Facilities, Healthcare"
        />
        <title>
          Madhurima Sewa Sansthan: Free Medical Camps, Mobile Clinics in India
        </title>
      </Helmet>
      <div className="health-care-main-section">
        <div className="position-relative">
          <img src={healthCareImage} className="w-100" alt="Health Care" />
          <div className="hero-section-right-side position-absolute top-50 end-0 translate-middle-y">
            <div className="p-3">
              <h1>स्वास्थ्य देखभाल</h1>
              <p>
                स्वस्थ शरीर में स्वस्थ मन वास करता है, इसलिए मधुरिमा सेवा
                संस्कार संस्थान ने समाज को बीमारी मुक्त बनाने के साथ स्वस्थ
                बनाने का संकल्प लिया है। सभी को स्वास्थ्य सेवाओं का लाभ मिले, इसके लिए स्वास्थ्य शिविरों का आयोजन किया जाता है। इन शिविरों के
                जरिए समाज के हर वर्ग तक स्वास्थ्य सेवाओं की पहुंच बढ़ रही है।
                इसके साथ उन्हें स्वस्थ रहने के मंत्र भी दिए जाते हैं।
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row left-sidebar">
            <div className="col-md-3">
              <aside>
                <ul className="subtabs">
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(weWorkRef)}
                  >
                    हम कैसे काम करते हैं
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(whatWeDoRef)}
                  >
                    हम क्या करते हैं
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(clinicOnWheelsRef)}
                  >
                    क्लिनिक ऑन व्हील्स क्या काम करता है
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(impact)}
                  >
                    प्रभाव 2022-23
                  </li>
                  <li
                    className="subtab"
                    onClick={() => scrollToSection(stories)}
                  >
                    कहानियाँ जो हमें प्रेरणा देती हैं
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-md-9">
              <section id="weWork" ref={weWorkRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम कैसे काम करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="we-work-text mt-3">
                  <p>
                    मधुरिमा सेवा संस्कार फाउंडेशन का पहला लक्ष्य भारत को बीमारी
                    मुक्त बनाने के साथ स्वस्थ भारत बनाना है। इसके लिए निरंतर
                    प्रयास जारी है। पहली कोशिश है गंभीर बीमारी को पनपने ही न
                    दिया जाए और जो सामान्य बीमारियों की गिरफ्त में आते हैं
                    उन्हें जल्दी सेहतमंद बनाए जाए। यही कारण है कि संस्थान मध्य
                    प्रदेश के विंध्य क्षेत्र के सतना जिले में ‘बीमारी मुक्त
                    भारत’ अभियान चला रहा है। बीमारी से मुक्ति के लिए जरूरी है कि
                    घर-घर तक स्वास्थ्य सुविधा पहुंचे और आसानी से उनकी जांच हो।
                    इस अभियान को गति देने के लिए अत्याधुनिक चिकित्सा सुविधाओं से
                    लैस क्लीनिक ऑन व्हील्स सेवा का शुभारंभ किया गया। पहियों पर
                    चलता यह अस्पताल सतना जिले के सुदूर क्षेत्रों मे जाकर मेडिकल
                    सेवा की सुविधाओं से वंचित लोगों को उनके घर पर स्वास्थ्य
                    सेवाएं उपलब्ध करा रहा है। निःशुल्क दवाएं देने के साथ केंद्र
                    सरकार की विभिन्न स्वास्थ्य संबंधी योजनाओं से अवगत कराया जा
                    रहा है। बीमारी को आने से ही रोकने के लिए मास हेल्थ
                    स्क्रीनिंग को आधार बनाया गया है। चिकित्सा क्षेत्र की यह ऐसी
                    तकनीक है जिसके जरिए व्यक्ति को आने वाले समय में होने वाली
                    बीमारी का पहले ही पता चल जाता है, जिससे उपचार के सहारे उस
                    बीमारी को रोका जा सकता है। सतना जिले में अब तक 30 हजार लोगों
                    का डाटा तैयार किया जा चुका है। यह पहले बीमारी मुक्त भारत की
                    दिशा में बड़ा कदम है। सतना जिले में स्थित श्री राम जी की
                    तपोभूमि चित्रकूट को आरोग्य बनाने की मधुरिमा सेवा संस्कार
                    फाउंडेशन की मुहिम जारी है। यहां के निवासियों को घर पर
                    स्वास्थ्य सुविधा मुहैया कराने का अभियान जारी है, शिविर लगाए
                    जा रहे है, नेत्र रोगियों के मोतियाबिंद के ऑपरेशन कराए गए, तो
                    उनकी जीवन शैली को बेहतर बनाने के प्रयास हो रहे है ताकि वे
                    निरोग रहे। संस्थान द्वारा 295 दिनों में 125 शिविरों का आयोजन
                    किया गया। इन शिविरों में 63480 मरीज का इलाज किया गया, इसके
                    अलावा 51490 मरीजों की पेशाब और खून की जांच की गई। इतना ही
                    नहीं 4 लाख 80 हजार यूनिट निशुल्क दवाइयां वितरित की गई। 2043
                    लोगों को चश्मो का वितरण किया गया और 106 मरीज के आंखों के
                    ऑपरेशन कराए गए।
                  </p>
                </div>
              </section>

              <section id="whatWeDo" ref={whatWeDoRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>हम क्या करते हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="we-work-text mt-3">
                  <div className="bus-section">
                    <div className="waves-icon">
                      <img src={waves} style={{ width: "100px" }} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <video
                        ref={videoRef}
                        controls
                        className="health-care-video-section"
                        poster={busIcon}
                        onClick={handleThumbnailClick}
                      >
                        <source
                          src={
                            "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v11.mp4"
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="d-flex justify-content-center">
                      <p>
                        क्लीनिक ऑन व्हील चलता फिरता अस्पताल है और वह पूरी तरह
                        चिकित्सकीय सुविधाओं से संपन्न है ।इस चलते फिरते अस्पताल
                        के चिकित्सक निशुल्क मरीजों को परामर्श देते हैं, खून और
                        पेशाब की जांच करते हैं साथ ही निशुल्क दवाइयां उपलब्ध
                        कराई जाती हैं। इतना ही नहीं इसके जरिए मास हेल्थ
                        स्क्रीनिंग से आने वाले समय में होने वाली बीमारी का भी
                        पता लगाया जाता है। क्लीनिक ऑन व्हील में मौजूद चिकित्सक
                        और पैरामेडिकल स्टाफ बीमारियों के प्रति लोगों में
                        जागरूकता लातलाते है ।उन्हें केंद्र और राज्य सरकार की
                        स्वास्थ्य संबंधी योजना से अवगत कराते है, नशा मुक्ति के
                        लिए परामर्श देते है। आम लोग कैसे स्वस्थ रहें और बीमारी
                        उन तक करीब न पहुंच पाए इसके लिए टिप्स भी दिए जाते हैं।
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="clinicOnWheels" ref={clinicOnWheelsRef}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>क्लिनिक ऑन व्हील्स क्या काम करता है</h2>
                </div>
                <hr className="hr-line" />
                <div className="mt-3 clinic-wheels">
                  <div className="row">
                    <div className="col-md-4">
                      <img src={ClinicWheels} className="w-100" />
                    </div>
                    <div className="col-md-8 ">
                      <p>
                        यह ऐसा वाहन है जो चलता फिरता अस्पताल है। आधुनिक
                        चिकित्सकीय सुविधाएं उपलब्ध होने के साथ चिकित्सा और
                        पैरामेडिकल स्टाफ मौजूद रहता है। यह सुदूर ग्रामीण इलाकों
                        में पहुंचकर उन लोगों की स्वास्थ्य संबंधी समस्या का
                        समाधान करता है जो प्राथमिक या जिला स्तरीय चिकित्सा
                        केंद्रों तक पहुंचने में सक्षम नहीं है। यह क्लीनिक ऑन
                        व्हील उन लोगों के लिए वरदान है जो अपनी व्यक्तिगत
                        समस्याओं के कारण सरकारी स्वास्थ्य केंद्रों तक नहीं पहुंच
                        पाए।
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="impact" ref={impact}>
                <div className="we-work">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>प्रभाव 2022-23</h2>
                </div>
                <hr className="hr-line" />
                <div className="mt-3 stories-section">
                  <div style={{ padding: "50px" }}>
                    <h5>प्रभाव 2022-23</h5>
                    <br />
                    <h3>
                      अबतक 314 दिनों में 135 शिविरों का सफल आयोजन (8 मई 2023 -
                      16 मार्च 2024 (निरंतर जारी))
                    </h3>

                    <div
                      className="row"
                      style={{ display: "flex", marginTop: "28px" }}
                    >
                      <div className="col-md-4">
                        <h4>1,00,000+</h4>
                        <p>लोगों का स्वास्थ्य शिविरों में स्वास्थ्य परीक्षण</p>
                      </div>

                      <div className="col-md-4">
                        <h4>17,283+</h4>
                        <p>
                          डिजिटल स्वास्थ्य शिविरों में पंजीकृत लोगों का कंपलीट
                          डिजिटल हेल्थ प्रोफाइल
                        </p>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ display: "flex", marginTop: "28px" }}
                    >
                      <div className="col-md-3">
                        <h4>4,40,000+</h4>
                        <p>निःशुल्क दवाइ वितरण</p>
                      </div>

                      <div className="col-md-3">
                        <h4>2043</h4>
                        <p>चश्मा वितरण</p>
                      </div>
                      <div className="col-md-3">
                        <h4>106</h4>
                        <p>नेत्र रोगियों के सफल ऑपरेशन</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="stories" ref={stories}>
                <div className="we-work mt-4">
                  <button>
                    <img src={rightAngle} />
                  </button>
                  <h2>कहानियाँ जो हमें प्रेरणा देती हैं</h2>
                </div>
                <hr className="hr-line" />
                <div className="carousel-container">
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="carousel-slide">
                        <img
                          src={image.imageUrl}
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => openModal(image)}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={{
                    content: {
                      width: "100%",
                      margin: "auto",
                      border: "none",
                      background: "none",
                      padding: 0,
                      inset: 0,
                    },
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.85)",
                    },
                  }}
                >
                  <div>
                    <button onClick={closeModal} className="close-btn">
                      <img src={closeBtn} />
                    </button>
                  </div>

                  {currentImage && (
                    <video controls autoPlay className="display-video">
                      <source src={currentImage.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Modal>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthCare;

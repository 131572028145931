import React, { useEffect, useRef, useState } from "react";
import "./Games.scss";
import healthCareImage from "../../../Assets/images/OurJob/Games/games-bg.png";
import rightAngle from "../../../Assets/images/OurJob/HealthCare/right-angle.svg";
import game1 from "../../../Assets/images/OurJob/Games/game1.png";
import game2 from "../../../Assets/images/OurJob/Games/game2.png";
import game3 from "../../../Assets/images/OurJob/Games/game3.png";
import game4 from "../../../Assets/images/OurJob/Games/game4webp.webp";
import game5 from "../../../Assets/images/OurJob/Games/game5.webp";
import game6 from "../../../Assets/images/OurJob/Games/game6.webp";
import game7 from "../../../Assets/images/OurJob/Games/game7webp.webp";
import game8 from "../../../Assets/images/OurJob/Games/game8.webp";
import game11 from "../../../Assets/images/OurJob/Games/game11.webp";
import game12 from "../../../Assets/images/OurJob/Games/game12.webp";
import game13 from "../../../Assets/images/OurJob/Games/game13.webp";
import game14 from "../../../Assets/images/OurJob/Games/game14.webp";
import game15 from "../../../Assets/images/OurJob/Games/game15.webp";
import game16 from "../../../Assets/images/OurJob/Games/game16.webp";
import game17 from "../../../Assets/images/OurJob/Games/game17.webp";
import game18 from "../../../Assets/images/OurJob/Games/game18.webp";
import game19 from "../../../Assets/images/OurJob/Games/game19.webp";
import game20 from "../../../Assets/images/OurJob/Games/game20.webp";
import game21 from "../../../Assets/images/OurJob/Games/game21.webp";
import game22 from "../../../Assets/images/OurJob/Games/game22.webp";
import { Helmet } from "react-helmet";

const gameEvents = [
  {
    button: "दिव्यांग क्रिकेट टूर्नामेंट",
    mainText:
      "मैहर के दिव्यांग क्रिकेट टूर्नामेंट ने दिया चुनौती से लड़ने का हौसला",
    subText:
      "मैहर में मधुरिमा सेवा संस्कार फाउंडेशन द्वारा आयोजित तीन दिवसीय दिव्यांग क्रिकेट टूर्नामेंट ने दशकों को चुनौतियों से लड़ने का हौसला दिया । इस टूर्नामेंट में हिस्सा लेने आए कई खिलाड़ी ऐसे थे , जिनके शारीरिक अंगों को या तो हादसों में छीन लिया था या फिर वे पैदाइशी तौर पर दिव्यांग थे। इन खिलाड़ियों ने यह नजर ही नहीं आया कि वे दिव्यांग है। भले ही उनका एक पैर या हांथ नहीं है मगर उन्होंने अपने खेल के दौरान इसे जाहिर ही नहीं होने दिया। इस टूर्नामेंट में हिस्सा लेने आज आए दिव्यांग खिलाड़ियों का माधुरिमा सेवा संस्कार फाउंडेशन की संस्थापिका डॉक्टर स्वप्ना वर्मा ने भी उत्साह बढ़ाने में कोई कोर कसर नहीं छोड़ी।",
    gamesImages: [game4, game5, game6, game7, game8, game22],
    image: game1,
  },
  {
    button: "फुटबॉल टूर्नामेंट",
    mainText: "शहीद ठाकुर रणमत सिंह स्मृति भारतीय फुटबाल प्रतियोगिता",
    subText:
      "सतना जिले की कोठी में शहीद ठाकुर रणमत सिंह स्मृति 42 वीं अखिल भारतीय फुटबाल प्रतियोगिता आयोजित की गई। इस प्रतियोगिता के फाइनल में एम आई जी बेंगलुरु ने नीमच को शिकस्त देकर खिताब जीता। मधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉ स्वप्ना वर्मा ने समापन समारोह में मुख्य अतिथि के तौर पर उपस्थित रहकर विजेताओं को पुरस्कार वितरित किए। विजेता को 31,000 और उपविजेता नीमच को 21,000 रुपए की नगद राशि प्रदान की गई।",
    gamesImages: [game11, game12, game13, game14, game15, game16],
    image: game2,
  },
  {
    button: "खो-खो प्रतियोगिता",
    mainText:
      "रामाकृष्णा महाविद्यालय सतना में अंतर्विश्वविद्यालय पश्चिम क्षेत्र खो-खो प्रतियोगिता ।",
    subText:
      "सतना के रामा कृष्ण महाविद्यालय में अंतर विश्वविद्यालयीन पश्चिम क्षेत्र बालिका खो-खो प्रतियोगिता का आयोजन किया गया। इस प्रतियोगिता में बालिकाओं ने अपने उत्कृष्ट खेल का प्रदर्शन किया। मधुरिमा सेवा संस्कार संस्थान की संस्थापिका डॉ स्वप्ना वर्मा ने इन खिलाड़ियों का उत्साह वर्धन करते हुए पुरस्कार वितरण किया।",
    gamesImages: [game17, game18, game19, game20, game21],
    image: game3,
  },
];

function Games() {
  const weWorkRef = useRef(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);

  const handleButtonClick = (index) => {
    setSelectedEventIndex(index);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute("href", "https://www.madhurimasanskar.org/sports");
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
      <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/sports" />
        <meta
          name="description"
          content="Madhurima Sewa Sanskar Sansthan organizes sports competitions for all! We develop passion for cricket, football, volleyball & support disabled athletes. Explore upcoming sports events in Satna!"
        />
        <meta
          name="keywords"
          content="Sports competitions, Madhurima Sewa Sansthan, cricket, football, volleyball, disabled cricket tournament, Maihar"
        />
        <title>Madhurima Sansthan: Promote Sports, Empowering Youth</title>
      </Helmet>
      <div className="game-bg">
        <div className="position-relative">
          <img src={healthCareImage} className="w-100" alt="Health Care" />
          <div className="hero-section position-absolute top-50 start-0 translate-middle-y">
            <div className="p-3">
              <h1>खेल</h1>
              <p>
                पढ़ाई- लिखाई के साथ खेल भी जिंदगी का महत्वपूर्ण हिस्सा है ।
                खेलों के प्रति नई पीढ़ी में लगाव बढे इसके लिए विभिन्न खेल
                स्पर्धाओं का आयोजन मधुरिमा सेवा संस्कार संस्थान द्वारा किया जाता
                है। वहीं विभिन्न संस्थाओं द्वारा आयोजित किए जाने वाले टूर्नामेंट
                को भी संस्था की ओर से सहयोग किया जाता है ।क्रिकेट, फुटबॉल,
                वॉलीबॉल जैसे टूर्नामेंट में हिस्सा लेने आने वाले खिलाड़ियों के
                प्रदर्शन से नई पीढ़ी में भी खेल के प्रति अभिरुचि जाग रही है। आने
                वाले समय में संभावना है कि सतना जिले से भी कई प्रतिभाएं
                राष्ट्रीय स्तर पर अपना जौहर दिखाएंगी।
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row games-left-sidebar">
            <div>
              <section id="weWork" ref={weWorkRef}>
                <div className="games-work mt-5">
                  <button>
                    <img src={rightAngle} alt="Right Angle" />
                  </button>

                  <h2>खेल है जिंदगी का हिस्सा</h2>
                </div>
                <hr className="hr-line" />

                <div className="container">
                  <div className="row d-flex justify-content-center sports-section">
                    {gameEvents.map((event, index) => (
                      <div className="col-md-3" key={index}>
                        <img src={event.image} className="w-100" alt="Game" />
                        <div className="d-flex justify-content-center button-section ">
                          <button
                            onClick={() => handleButtonClick(index)}
                            style={{
                              backgroundColor:
                                selectedEventIndex === index
                                  ? "#6B6B6B"
                                  : "#AD2224",

                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {event.button}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <div className="row">
                <div className="container games-photo-section">
                  <div className="col-md-2"></div>
                  <div className="col-md-8 text-center">
                    <div>
                      <h2>{gameEvents[selectedEventIndex].mainText}</h2>
                    </div>
                    <div>
                      <h4>{gameEvents[selectedEventIndex].subText}</h4>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <section>
                <div className="row mt-3 d-flex justify-content-center sports-section">
                  {gameEvents[selectedEventIndex].gamesImages
                    .slice(0, 3)
                    .map((image, imgIndex) => (
                      <div className="col-md-4 mt-3" key={imgIndex}>
                        <img
                          src={image}
                          className="game-images w-100"
                          alt={`Game ${imgIndex + 1}`}
                        />
                      </div>
                    ))}
                </div>
                <div className="row mt-4 d-flex justify-content-center sports-section">
                  {gameEvents[selectedEventIndex].gamesImages
                    .slice(3)
                    .map((image, imgIndex) => (
                      <div className="col-md-4 mt-3" key={imgIndex}>
                        <img
                          src={image}
                          className="game-images w-100"
                          alt={`Game ${imgIndex + 1}`}
                        />
                      </div>
                    ))}
                </div>
              </section>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Games;

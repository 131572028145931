import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./PhotoGallery.scss";
import backBtn from "../../../Assets/images/MediaGallery/PhotoGallery/back-icon.png";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader";
import { Helmet } from "react-helmet";
const PhotoGallery = () => {
  const [selectedSubImages, setSelectedSubImages] = useState([]);
  const [selectedCardTitle, setSelectedCardTitle] = useState("");
  const [selectedDate, setselectedDate] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedSubImages, selectedCardTitle, selectedDate]);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    {
      id: "01",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/20-july.png",
      cardTitle:
        "सतना जिले की सेवा बस्ती और चित्रकूट के बटोही गांव में लगभग 700 से अधिक परिवारजनों को सूखा राशन वितरित किया गए।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/669bc26d7397906d99991308_images/jun1.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/669bc26d7397906d99991308_images/jun2.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/669bc26d7397906d99991308_images/jun3.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/669bc26d7397906d99991308_images/jun4.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/669bc26d7397906d99991308_images/jun5.png"
        
      ],
      date: "29 जून 2024",
    },
    {
      id: "1",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/event1.png",
      cardTitle:
        "चिकित्सा के क्षेत्र में उत्कृष्ट कार्य करने के लिए डॉ स्वप्ना वर्मा का दैनिक भास्कर द्वारा भोपाल में आयोजित ‘प्राईड ऑफ मध्य प्रदेश -2024’ में मध्य प्रदेश के माननीय मुख्यमंत्री डॉ मोहन यादव द्वारा सम्मान।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65f4541947f7bf7e725c459d_images/event1.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65f4541947f7bf7e725c459d_images/event3.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65f4541947f7bf7e725c459d_images/event2.png",
      ],
      date: "13 मार्च 2024",
    },
    {
      id: "2",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/feb-08.jpeg",
      cardTitle:
        "मध्यप्रदेश के सतना की बेटी डॉ स्वप्ना वर्मा को दिल्ली में आयोजित कार्यक्रम में केंद्रीय स्वास्थ्य मंत्री श्री मनसुख मांडविया जी ने सुश्रुत अवार्ड-2024 से सम्मानित किया।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65c4e4f37f1146edeab97a8d_images/feb-1.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65c4e4f37f1146edeab97a8d_images/feb-2.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65c4e4f37f1146edeab97a8d_images/feb-3.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65c4e4f37f1146edeab97a8d_images/feb-4.png",
      ],
      date: "13 मार्च 2024",
    },
    {
      id: "3",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/main-ev-01.png",
      cardTitle:
        "सतना संसदीय क्षेत्र के बटोही गांव में मधुरिमा सेवा संस्कार फाउंडेशन ने जनजातीय वर्ग के बच्चों को सनातनी संस्कार देने के लिए में मधुरिमा संस्कार पाठशाला शुरू की है।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8765f64a00856303276_images/sep-1.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8765f64a00856303276_images/sep-2.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8765f64a00856303276_images/sep-3.png",
      ],
      date: "13 मार्च 2024",
    },
    {
      id: "4",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/019-jan.png",
      cardTitle:
        "अयोध्या में रामलला के आगमन की खुशी में सतना के रामवन में मधुरिमा सेवा संस्कार फाउंडेशन के सदस्यों ने साफ - सफाई अभियान चलाया, दीपोत्सव मनाया और बहनों ने रंगोली बनाई।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b732ed865cd8c0d19aa2c4_images/1-jan.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b732ed865cd8c0d19aa2c4_images/2-jan.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b732ed865cd8c0d19aa2c4_images/3-jan.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b732ed865cd8c0d19aa2c4_images/4-jan.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b732ed865cd8c0d19aa2c4_images/5-jan.png",
      ],
      date: "13 मार्च 2024",
    },
    {
      id: "5",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/22Jan.jpeg",
      cardTitle:
        "अयोध्या में रामलला की प्राण प्रतिष्ठा के शुभ अवसर पर चित्रकूट में जनजातीय महिलाओं संग कामदगिरि पर्वत की परिक्रमा , मंदाकिनी नदी में दीपदान ।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_1.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_2.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_3.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_4.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_5.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_6.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_7.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_8.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_9.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_10.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_11.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65afc97d337d6ed315008236_images/jan22_12.jpeg",
      ],
      date: "22 जनवरी 2024",
    },

    // {
    //   id: "6",
    //   imageUrl:
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/012Jan.png",
    //   cardTitle:
    //     "स्वामी विवेकानंद जी की जन्मजयंती ‘राष्ट्रीय युवा दिवस’ के अवसर पर सतना में आयोजित संकल्प शोभा यात्रा में शामिल हाेकर युवाओं को सम्बोधित किया।",
    //   subImages: [
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65a4be6409dd442cbb42fd27_images/12Jan_1.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65a4be6409dd442cbb42fd27_images/12Jan_2.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65a4be6409dd442cbb42fd27_images/12Jan_3.jpeg",
    //   ],
    //   date: "12 जनवरी 2024",
    // },
    // {
    //   id: "7",
    //   imageUrl:
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/10_Jan.jpeg",
    //   cardTitle:
    //     "मध्यप्रदेश के सतना जिला स्थित धार्मिक नगरी मैहर में आयोजित तीन दिवसीय दिव्यांग क्रिकेट टूर्नामेंट के समापन समारोह की अध्यक्षता की।",
    //   subImages: [
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan41.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan42.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan43.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan44.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan45.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan46.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan47.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan48.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan49.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan50.jpeg",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659fb69b63015f63d8481164_images/jan51.jpeg",
    //   ],
    //   date: "10 जनवरी 2024",
    // },
    {
      id: "8",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/07Jan.jpeg",
      cardTitle:
        "विकसित भारत संकल्प यात्रा के संदेश से आमजन अवगत कराते हुए मैहर के बैहार ग्राम में बैगा जाति के लोगों को आयुष्मान कार्ड और इसके फायदे के बारे में बताया",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659e98513d121e9f67066f89_images/jan20.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659e98513d121e9f67066f89_images/jan21.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/659e98513d121e9f67066f89_images/jan22.jpeg",
      ],
      date: "8 जनवरी 2024",
    },
    {
      id: "9",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/dec_25.png",
      cardTitle:
        "सुषेण संजीवनी शिविर से मरीजों को मिल रहा लाभ। स्वास्थ्य परीक्षण, खून व पेशाब की जांच और निःशुल्क दवाओं का वितरण।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-1.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-2.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-3.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-4.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-5.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-6.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-7.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-8.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/658ad290a7b12034305f9947_images/dec25-9.png",
      ],
      date: "7 दिसंबर 2023",
    },
    {
      id: "10",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/08jan.png",
      cardTitle:
        "स्वास्थ्य शिविर में जाँच रिपोर्ट का वितरण जिसमें आने वाले समय में होने वाली बीमारी का विवरण और उपचार का परामर्श।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/6523c70e86ccae7563c1228a_images/08oct-0.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/6523c70e86ccae7563c1228a_images/08oct-2.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/6523c70e86ccae7563c1228a_images/08oct-3.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/6523c70e86ccae7563c1228a_images/08oct-4.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/6523c70e86ccae7563c1228a_images/08oct-5.png",
      ],
      date: "8 अक्टूबर 2023",
    },
    {
      id: "11",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/15-sep1.png",
      cardTitle:
        "सतना जिले में निःशुल्क स्वास्थ्य जाँच शिविर, 291 दिन 120 स्वास्थ्य शिविर लगे।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep1.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep2.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep3.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep4.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep5.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep6.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep7.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65e09a8ca7b1019ec8622eef_images/sep8.png",
      ],
      date: "15 सितम्बर 2023",
    },
    // {
    //   id: "12",
    //   imageUrl:
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/main-ev-03.png",
    //   cardTitle:
    //     "सरस्वती शिशु मंदिर उच्चतर माध्यमिक विद्यालय जैतवारा में गणतंत्र दिवस का ध्वजारोहण किया और छात्र-छात्राओं से राष्ट्र हित में अपना योगदान देने का आह्वान किया।",
    //   subImages: [
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-50.png",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-52.png",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-53.png",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-54.png",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-55.png",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-56.png",
    //     "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/65b8f8857190ed00c22e0534_images/ev-57.png",
    //   ],
    //   date: "26 जनवरी 2024",
    // },
    {
      id: "13",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/new_event-aug23_1.JPG",
      cardTitle:
        "बीमारी मुक्त भारत के लिए सतना में क्लिनीक ऑन व्हील्स का समाज के प्रबुद्धजनों और संतों की उपस्थिति में शुभारंभ।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f7099c920d3a551cbe7fd7_images/image1.JPG",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f7099c920d3a551cbe7fd7_images/image2.JPG",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f7099c920d3a551cbe7fd7_images/image4.JPG",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f7099c920d3a551cbe7fd7_images/image5.JPG",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f7099c920d3a551cbe7fd7_images/image6.JPG",
      ],
      date: "23 अगस्त 2023",
    },
    {
      id: "14",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/event-aug-017.png",
      cardTitle:
        "मधुरिमा सेवा संस्कार फाउंडेशन द्वारा सरकारी स्कूल के छात्र-छात्राओं से संवाद और उपहार का वितरण।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f70c3994f6ddd21f96fd62_images/ev-1.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f70c3994f6ddd21f96fd62_images/ev-2.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f70c3994f6ddd21f96fd62_images/ev-3.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f70c3994f6ddd21f96fd62_images/ev-4.jpeg",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f70c3994f6ddd21f96fd62_images/ev-5.jpeg",
      ],
      date: "17 अगस्त 2023",
    },
    {
      id: "15",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/event-july027.png",
      cardTitle:
        "मधुरिमा सेवा संस्कार संस्थान ने बड़ी संख्या में नेत्र रोगियों का मोतियाबिंद और रेटिना के ऑपरेशन के लिए बस से रवाना किया।",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-55.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-56.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-57.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-58.png",
      ],
      date: "27 जुलाई 2023",
    },
    {
      id: "16",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/event-july027.png",
      cardTitle:
        "मध्यप्रदेश के सतना की ऐतिहासिक भूमि पर निःशुल्क नेत्र-परीक्षण का तीन दिवसीय महाशिविर, 5000 लोगों का नेत्र परीक्षण",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-55.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-56.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-57.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8545925277f1edf471a7b_images/ev-58.png",
      ],
      date: "27 जुलाई 2023",
    },
    {
      id: "17",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/event-may9_3.png",
      cardTitle:
        "नेत्र परीक्षण शिविर के दूसरे दिन चिकित्सकों ने सेवा व समर्पण भाव से की मरीजों की जांच",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-1.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-2.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-3.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-4.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-5.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-6.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-7.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-8.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-9.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-11.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-12.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-13.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f8590ccb3d8f723fe58159_images/events-14.png",
      ],
      date: "9 मई 2023",
    },
    {
      id: "18",
      imageUrl:
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventImages/event-april1.png",
      cardTitle: "बीमारी मुक्त भारत के लिए क्लीनिक ऑन व्हील्स का शुभारंभ",
      subImages: [
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f85b4964aa2d2f9191e855_images/events-46.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f85b4964aa2d2f9191e855_images/events-48.png",
        "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/eventDetailImages/64f85b4964aa2d2f9191e855_images/events-50.png",
      ],
      date: "1 अप्रैल 2023",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const titleText = "फोटो गैलरी";
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/photo-gallery"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.madhurimasanskar.org/photo-gallery"
        />
        <meta
          name="description"
          content="Witness the impact of Madhurima Sewa Sanskar Foundation's work through our photo gallery. See inspiring images of our projects and the lives we touch."
        />
        <meta
          name="keywords"
          content="Madhurima Sewa Sanskar Foundation, photo gallery, charity work, social impact, Community, Charitable Work"
        />
        <title>Madhurima Sewa Sanskar Foundation - Photo Gallery</title>
      </Helmet>
      <div id="photo-gallery">
        <div className="photo-gallery-header">
          <TitleHeader title={titleText} />
        </div>
        <br />
        <br />
        <br />

        <div className="container">
          {selectedSubImages.length > 0 && (
            <div className="container mt-5">
              <div className="card-title">
                <h3>{selectedCardTitle}</h3>
                <div className="d-flex justify-content-center">
                  <h4>{selectedDate}</h4>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                {selectedSubImages.map((subImage, subIndex) => (
                  <div key={subIndex} className="col-md-4 mt-4">
                    <div className="card">
                      <img
                        src={subImage}
                        className="card-img sub-images"
                        alt={`Sub Slide ${subIndex}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <hr className="hr-line" />
            </div>
          )}
          <div>
            <div className="carousel-container">
              <div className="container mt-5">
                <Slider {...settings}>
                  {images.map((item, index) => (
                    <div key={index} className="carousel-slide">
                      <div className="card">
                        <img
                          src={item.imageUrl}
                          className="card-img"
                          alt={`Slide ${index}`}
                        />
                        <div className="card-body">
                          <p className="card-text">{item.cardTitle}</p>
                          <div
                            className="justify-content-end d-flex"
                            style={{ gap: 8, marginTop: 42 }}
                          >
                            <h2
                              onClick={() => {
                                setSelectedSubImages(item.subImages);
                                setSelectedCardTitle(item.cardTitle);
                                setselectedDate(item.date);
                              }}
                            >
                              आगे पढ़े
                            </h2>
                            <div className="mt-1">
                              <img src={backBtn} className="back-btn" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default PhotoGallery;

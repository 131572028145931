import React, { useEffect } from "react";
import "./DigitalPrint.scss";
import digitalIcon1 from "../../../Assets/images/DigitalPrint/digital-icon1.webp";
import digitalIcon2 from "../../../Assets/images/DigitalPrint/digital-icon2.webp";
import digitalIcon3 from "../../../Assets/images/DigitalPrint/digital-icon3.webp";
import digitalIcon4 from "../../../Assets/images/DigitalPrint/digital-icon4.webp";
import digitalIcon5 from "../../../Assets/images/DigitalPrint/digital-icon5.webp";
import digitalIcon6 from "../../../Assets/images/DigitalPrint/digital-icon6.webp";
import digitalIcon7 from "../../../Assets/images/DigitalPrint/digital-icon7.webp";
import digitalIcon8 from "../../../Assets/images/DigitalPrint/digital-icon8.webp";
import digitalIcon9 from "../../../Assets/images/DigitalPrint/digital-icon9.webp";
import digitalIcon10 from "../../../Assets/images/DigitalPrint/digital-icon10.webp";
import digitalIcon11 from "../../../Assets/images/DigitalPrint/digital-icon11.webp";
import digitalIcon12 from "../../../Assets/images/DigitalPrint/digital-icon12.webp";
import digitalIcon13 from "../../../Assets/images/DigitalPrint/digital-icon13.webp";
import digitalIcon14 from "../../../Assets/images/DigitalPrint/digital-icon14.webp";
import digitalIcon15 from "../../../Assets/images/DigitalPrint/digital-icon15.webp";
import digitalIcon16 from "../../../Assets/images/DigitalPrint/digital-icon16.webp";
import digitalIcon17 from "../../../Assets/images/DigitalPrint/digital-icon17.webp";
import digitalIcon18 from "../../../Assets/images/DigitalPrint/digital-icon18.webp";
import digitalIcon19 from "../../../Assets/images/DigitalPrint/digital-icon19.webp";
import digitalIcon20 from "../../../Assets/images/DigitalPrint/digital-icon20.webp";
import digitalIcon21 from "../../../Assets/images/DigitalPrint/digital-icon21.webp";
import digitalIcon22 from "../../../Assets/images/DigitalPrint/digital-icon22.webp";
import digitalIcon23 from "../../../Assets/images/DigitalPrint/digital-icon23.webp";
import digitalIcon24 from "../../../Assets/images/DigitalPrint/digital-icon24.webp";
import digitalIcon25 from "../../../Assets/images/DigitalPrint/digital-icon25.webp";
import digitalIcon26 from "../../../Assets/images/DigitalPrint/digital-icon26.webp";
import digitalIcon27 from "../../../Assets/images/DigitalPrint/digital-icon27.webp";
import digitalIcon28 from "../../../Assets/images/DigitalPrint/digital-icon28.webp";
import digitalIcon29 from "../../../Assets/images/DigitalPrint/digital-icon29.webp";
import aug1 from "../../../Assets/images/DigitalPrint/aug-1.png";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader";
import { Helmet } from "react-helmet";
const galleryItems = [
  {
    id: 1,
    image: aug1,
    videoLink:
      "https://prakharpravaktanews.in/blog/5562/",
    text: "सतना वसियों के मन में सिर्फ मोदी: डॉ स्वप्ना वर्मा",
    location: "",
    subDate: "21 अप्रैल 2024",
  },
  {
    id: 1,
    image: digitalIcon28,
    videoLink: "https://www.youtube.com/watch?v=sozLkZ70dCM",
    text: "सतना में शुरू हुई संस्कार पाठशाला",
    location: "",
    subDate: "जनवरी 31, 2024",
  },
  // {
  //   id: 2,
  //   image: digitalIcon29,
  //   videoLink: "https://www.youtube.com/watch?v=sozLkZ70dCM",
  //   text: "सतना वसियों के मन में सिर्फ मोदी: डॉ स्वप्ना वर्मा",
  //   location: "",
  //   subDate: "21 अप्रैल 2024",
  // },
  {
    id: 3,
    image: digitalIcon1,
    videoLink:
      "https://hindi.latestly.com/lifestyle/dr-swapna-verma-known-as-doctors-daughter-is-busy-in-the-campaign-to-conquer-the-disease-2095919.html",
    text: "मध्य प्रदेश के सतना जिले में डॉक्टर बेटी कहीं जानेवाली डॉ. स्वप्ना वर्मा बीमारी पर जीत हासिल करने की मुहिम में जुटी",
    location: "",
    subDate: "मार्च 8, 2024",
  },
  // {
  //   id: 4,
  //   image: digitalIcon2,
  //   videoLink: "https://www.bhaskarhindi.com/other/news-1008579",
  //   text: "महिला दिवस विशेष बीमारी के होने से पहले ही पता लगाकर इलाज शुरू कर देती है ‘डॉक्टर बेटी’",
  //   location: "",
  //   subDate: "मार्च 8, 2024",
  // },
  {
    id: 5,
    image: digitalIcon3,
    videoLink:
      "https://jantaserishta.com/national/womens-day-special-doctor-beti-detects-the-disease-before-it-occurs-and-starts-treatment-3153503",
    text: "महिला दिवस विशेष: बीमारी के होने से पहले ही पता लगाकर इलाज शुरू कर देती है ‘डॉक्टर बेटी’",
    location: "",
    subDate: "मार्च 8, 2024",
  },
  {
    id: 6,
    image: digitalIcon4,
    videoLink:
      "https://www.etvbharat.com/hi/!health/dr-swapna-verma-special-story-on-international-womens-day-womens-day-hin24030804388",
    text: "बीमारी से पहले ही पता लगाकर इलाज शुरू कर देती है ये ‘डॉक्टर बेटी’",
    location: "",
    subDate: "जनवरी 8, 2024",
  },
  {
    id: 7,
    image: digitalIcon5,
    videoLink: "https://www.youtube.com/watch?v=ywOvoB-vIQc",
    text: "सतना की डॉ. स्वप्ना वर्मा को सुश्रुत अवॉर्ड, पीएम मोदी की तारीफ",
    location: "",
    subDate: "जनवरी 8, 2024",
  },
  {
    id: 8,
    image: digitalIcon7,
    videoLink: "https://indias.news/",
    text: "चित्रकूट से मंदाकिनी का जल लेकर अयोध्या जाएंगे साधु-संत",
    location: "",
    subDate: "दिसंबर 5, 2023",
  },
  // {
  //   id: 9,
  //   image: digitalIcon8,
  //   videoLink: "https://hi.investing.com/news/general/article-113923",
  //   text: "चित्रकूट से मंदाकिनी का जल लेकर अयोध्या जाएंगे साधु-संत",
  //   location: "",
  //   subDate: "दिसंबर 5, 2023",
  // },
  // {
  //   id: 10,
  //   image: digitalIcon9,
  //   videoLink:
  //     "https://www.punjabkesari.com/india-news/sadhus-and-saints-will-go-to/",
  //   text: "Chitrakoot से मंदाकिनी का जल लेकर Ayodhya जाएंगे साधु-संत, सरयू नदी में करेंगे जलाभिषेक",
  //   location: "",
  //   subDate: "दिसंबर 5, 2023",
  // },
  // {
  //   id: 11,
  //   image: digitalIcon10,
  //   videoLink: "https://indias.news/",
  //   text: "चित्रकूट में जनकल्याण के लिए संत करेंगे कामदगिरि की परिक्रमा",
  //   location: "",
  //   subDate: "दिसंबर 4, 2023",
  // },
  // {
  //   id: 12,
  //   image: digitalIcon11,
  //   videoLink: "https://www.youtube.com/watch?v=hq6iNOKR9dw",
  //   text: "IPL की तर्ज पर मैहर में पहली बार डीपीएल की हुई शुरुआत,फाइनल मैच आज",
  //   location: "",
  //   subDate: "जनवरी 8, 2024",
  // },
  // {
  //   id: 13,
  //   image: digitalIcon12,
  //   videoLink:
  //     "https://www.facebook.com/BansalNewsOfficial/videos/1454134205528996/",
  //   text: "दिव्यांग प्रीमियर लीग का आगाज, मधुरिमा फाउंडेशन बना सहभागी",
  //   location: "",
  //   subDate: "जनवरी 8, 2024",
  // },
  {
    id: 14,
    image: digitalIcon13,
    videoLink: "https://www.youtube.com/watch?v=tfe858jk0-U",
    text: "लाखो लोगो का घर घर पहुंचकर इलाज करा रहीं मधुरिमा सेवा फाउंडेशन की डॉ. स्वप्ना वर्मा",
    location: "",
    subDate: "जनवरी 9, 2024",
  },
  {
    id: 15,
    image: digitalIcon14,
    videoLink: "https://www.facebook.com/ZeeMPCG/videos/1134780507901586/",
    text: "दिव्यांगों के लिए मिसाल बने युवा, खेल देख सब हैरान",
    location: "",
    subDate: "जनवरी 10, 2024",
  },
  // {
  //   id: 16,
  //   image: digitalIcon15,
  //   videoLink: "https://www.youtube.com/watch?v=8meCDmYdQLA",
  //   text: "Dr Swapna Verma अपने सपनो को पंख लगाने ! ज्वाइन किया बीजेपी",
  //   location: "",
  //   subDate: "सितम्बर 23, 2023",
  // },
  // {
  //   id: 17,
  //   image: digitalIcon16,
  //   videoLink:
  //     "https://www.facebook.com/newznagrimpcg/videos/829447185349599/?extid=CL-UNK-UNK-UNK-AN_GK0T-GK1C&mibextid=ZbWKwL",
  //   text: "Swapna Verma हुई BJP में सामिल | JP Nadda ने दिलाई सदस्यता",
  //   location: "",
  //   subDate: "सितम्बर 23, 2023",
  // },
  // {
  //   id: 18,
  //   image: digitalIcon17,
  //   videoLink: "https://www.youtube.com/watch?v=3C1JMCEfw7Q",
  //   text: "BJP में शामिल हुई सतना की जानीमानी डॉक्टर",
  //   location: "",
  //   subDate: "सितम्बर 23, 2023",
  // },
  // {
  //   id: 19,
  //   image: digitalIcon18,
  //   videoLink: "https://www.youtube.com/watch?v=RyU3ycLhvsU",
  //   text: "चित्रकूट में बीजेपी की जन आशीर्वाद यात्रा, सपना वर्मा ने कही ये बात",
  //   location: "",
  //   subDate: "सितम्बर 23, 2023",
  // },
  {
    id: 20,
    image: digitalIcon19,
    videoLink:
      "https://satnatimes.in/healthy-society-has-the-biggest-contribution-in-building-a-strong-nation-dr-swapna-verma/",
    text: "मजबूत राष्ट्र निर्माण में स्वस्थ समाज की सबसे बड़ी भागीदारी- डॉ स्वपना वर्मा",
    location: "",
    subDate: "सितम्बर 17, 2023",
  },
  {
    id: 21,
    image: digitalIcon20,
    videoLink:
      "https://rewanchalroshni.com/uncategorized/majbut-rastra-nirman/",
    text: "मजबूत राष्ट्र निर्माण में स्वस्थ समाज की सबसे बड़ी भागीदारी- डॉ स्वप्ना वर्मा",
    location: "",
    subDate: "दिसंबर 15, 2024",
  },
  {
    id: 22,
    image: digitalIcon21,
    videoLink: "https://hi.investing.com/news/general/article-119587",
    text: "मोदी के संकल्प’ को पूरा करने के लिए मुफ्त इलाज की मुहिम",
    location: "",
    subDate: "दिसंबर 25, 2023",
  },
  // {
  //   id: 23,
  //   image: digitalIcon22,
  //   videoLink: "https://www.youtube.com/watch?v=tr2WEyuctdo",
  //   text: "राजनीति भी मेरे लिए सेवा का माध्यम ही होगी- डॉ स्वपना वर्मा",
  //   location: "",
  //   subDate: "सितम्बर 30, 2023",
  // },
  {
    id: 24,
    image: digitalIcon23,
    videoLink:
      "https://prakharpravaktanews.wordpress.com/2023/09/23/%E0%A4%A1%E0%A4%BE%E0%A4%95%E0%A5%8D%E0%A4%9F%E0%A4%B0-%E0%A4%B8%E0%A5%8D%E0%A4%B5%E0%A4%AA%E0%A5%8D%E0%A4%A8%E0%A4%BE-%E0%A4%B5%E0%A4%B0%E0%A5%8D%E0%A4%AE%E0%A4%BE-%E0%A4%95%E0%A5%87-%E0%A4%B8/",
    text: "डाक्टर स्वप्ना वर्मा के स्वास्थ्य मुहिम पर सतना वासियो का बढ़ा विश्वास",
    location: "",
    subDate: "सितम्बर 16, 2023",
  },
  {
    id: 25,
    image: digitalIcon24,
    videoLink:
      "https://statebreak.in/satna-news-water-power-and-flood-control-minister-swatantra-dev-singh-boosted-everyones-morale-by-reaching-the-health-camp-of-dr-swapna-verma/",
    text: "जल शक्ति एवम् बाढ़ नियंत्रण मंत्री स्वतंत्र देव सिंह ने डॉ स्वप्ना वर्मा के स्वास्थ्य शिविर में पहुँच कर सबका मनोबल बढ़ाया.",
    location: "",
    subDate: "सितम्बर 20, 2023",
  },
 
];

function DigitalPrint() {
  const translatedText = 'डिजिटल प्रिंट';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/digital-print"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
     <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/digital-print" />
        <meta name="description" content="Explore Madhurima Sewa Sanskar Foundation in the form of digital Media. Get in touch with our social service excellence!" />
        <meta name="keywords" content="digital printing, printing services, Madhurima Sewa Sanskar Foundation, charitable organization" />
        <title>Madhurima Sewa Sanskar Foundation - Digital Print</title>
      </Helmet>
          <TitleHeader title={translatedText} />
          <div className="sub-main-container">
      <div className="content-digital-print">
        <div className="digital-print">
          <section className="container myvoice-main-container">
            <br/>
            <br/>
            <br/>
            <div className="container mt-5">
              <div className="row mt-5">
                {galleryItems.map((item) => (
                  <div className="col-md-4 mt-5" key={item.id}>
                    <div className="digital-print-section-card">
                      <a
                        href={item.videoLink}
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src={item.image} alt="Card image cap" />
                      </a>
                      {/* <div className="">
                        <br />
                        <h5 className="card-head">{t(item.text)}</h5>
                      </div> */}
                    </div>

                    <div className="sub-text-digital">
                      <p className="card-head">{item.text}</p>
                      <p className="right-side-date">{item.subDate}</p>
                      {/* <img
                        src={sharingIcon}
                        className="sharing-icon"
                        onClick={handleShareClick}
                      /> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </section>
        </div>
      </div>
    </div>
    </div>
  );
}

export default DigitalPrint;

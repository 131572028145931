import React, { useEffect } from "react";
import "./VideoGallery.scss";
import gallerImg1 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-1.webp";
import gallerImg2 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-2.webp";
import gallerImg3 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-3.webp";
import gallerImg4 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-4.webp";
import gallerImg5 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-5.webp";
import gallerImg6 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-6webp.webp";
import gallerImg7 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-7.webp";
import gallerImg8 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-8.webp";
import gallerImg9 from "../../../Assets/images/MediaGallery/VideoGallery/gallery-img-9.webp";
import TitleHeader from "../../../Components/TitleHeader/TitleHeader";
import { Helmet } from "react-helmet";
const galleryItems = [
  {
    id: 1,
    image: gallerImg1,
    videoLink:
      "https://drive.google.com/file/d/1OfKUEirH2OoPPuypsNOpMv0xPe7EnY7b/view",
    text: "बीमारी मुक्त ग्रामीण भारत",
    location: "स्थान: सतना",
    subDate: "10 अगस्त 2023",
  },
  {
    id: 2,
    image: gallerImg2,
    videoLink: "https://www.youtube.com/watch?v=kQojgrNmbZg",
    text: "रीवा की जनता को मिलेगा निःशुल्क स्वास्थ्य सुरक्षा का लाभ; विधायक राजेंद्र शुक्ल ने दी जानकारी",
    location: "स्थान: रीवा",
    subDate: "7 अगस्त 2023",
  },
  {
    id: 3,
    image: gallerImg3,
    videoLink: "https://www.youtube.com/watch?v=J2OI81CBGxw",
    text: "सतना संकल्प - महा नेत्र शिविर",
    location: "स्थान: सतना",
    subDate: "8 मई 2023",
  },
  {
    id: 4,
    image: gallerImg4,
    videoLink: "https://www.youtube.com/watch?v=FKnQgWyUcw0",
    text: "महा नेत्र जांच शिविर कार्यक्रम | मधुरिमा सेवा संस्कार संसथान | सेवा ही परम धर्म है",
    location: "स्थान: सतना",
    subDate: "8 मई 2023",
  },
  // {
  //   id: 5,
  //   image: gallerImg5,
  //   videoLink: "https://www.youtube.com/watch?v=Eknu0toSdrU&feature=youtu.be",
  //   text: "gallery.gallery_card12",
  //   location: "gallery.location4",
  //   subDate: "gallery.gallerDte4",
  // },
  {
    id: 6,
    image: gallerImg6,
    videoLink: "https://www.youtube.com/watch?v=LytnRlZDzsY&feature=youtu.be",
    text: "सदगुरु नेत्रालय में मोतियाबिंद के ऑपरेशन से पहले मरीज़ों से मुलाक़ात कर उनका मनोबल बढ़ाया",
    location: "स्थान: सतना",
    subDate: "21 जुलाई 2023",
  },
  {
    id: 9,
    image: gallerImg9,
    videoLink:
      "https://drive.google.com/file/d/1bSgdVU2umayiPi5qQWUhZ_obkRu87Hsm/view",
    text: "इन्नरव्हील क्लब सतना उद्‌गम द्वारा आयोजित “स्वर्णिम मेला”",
    location: "स्थान: सतना",
    subDate: "21 जुलाई 2023",
  },
  // {
  //   id: 7,
  //   image: gallerImg7,
  //   videoLink: "https://www.youtube.com/watch?v=uwVbVPm5Ojs&feature=youtu.be",
  //   text: "इन्नरव्हील क्लब सतना उद्‌गम द्वारा आयोजित “स्वर्णिम मेला”",
  //   location: "स्थान: सतना",
  //   subDate: "21 जुलाई 2023",
  // },
  {
    id: 8,
    image: gallerImg8,
    videoLink:
      "https://drive.google.com/file/d/1NsWoYvWLZadpUBDmwmnHnudyL6dPMePN/view",
    text: "क्लीनिक ऑन व्हील्स: देश के गांवों को रोगमुक्त बनाने की दिशा में एक सार्थक प्रयास",
    location: "स्थान: बेंगलुरु",
    subDate: "1 अप्रैल 2023",
  },
];

function VideoGallery() {
  const titleText = "वीडियो गैलरी";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute("href", "https://www.madhurimasanskar.org/video");
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container content-myvoice">
      <Helmet>
        <link rel="canonical" href="https://www.madhurimasanskar.org/video" />
        <meta
          name="description"
          content="The Madhurima Sewa Sanskar Foundation empowers communities in India through education, healthcare, and social services. Learn more about social contribution!"
        />
        <meta
          name="keywords"
          content="Madhurima Sewa Sanskar Foundation, NGO India, Charitable Organization India Education India, Healthcare India, Social Services India, Community Development India"
        />
        <title>Madhurima Sewa Sanskar Foundation - Video</title>
      </Helmet>
      <TitleHeader title={titleText} />
      <div className="sub-main-container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="gallery">
          <section className="container myvoice-main-container">
            <div className="container">
              <div className="row ">
                {galleryItems.map((item) => (
                  <div className="col-md-4   mt-2" key={item.id}>
                    <div className="my-voice-section-card">
                      <a
                        href={item.videoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={item.image} alt="Card image cap" />
                      </a>
                      <div className="">
                        <br />
                        <h2 className="card-head">{item.text}</h2>
                      </div>
                    </div>
                    <div className="sub-text-voice">
                      <p>{item.location}</p>
                      <p>{item.subDate}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default VideoGallery;

import React, { useEffect, useRef, useState } from "react";
import aboutImage from "../../Assets/images/about/about.webp";
import "./About.scss";
import leftImage from "../../Assets/images/about/left-img.png";
import mission from "../../Assets/images/about/mission.webp";
import purpose from "../../Assets/images/about/purpose.webp";
import target from "../../Assets/images/about/target-img.webp";
import weWork from "../../Assets/images/about/we-work-bg.svg";
import weWorkVideo from "../../Assets/images/about/we-work-video.webp";
import rightAngle from "../../Assets/images/about/right-angle.svg";
import leftAngle from "../../Assets/images/about/left-angle.svg";
import profileImage from "../../Assets/images/about/profile-image.webp";
import { Helmet } from "react-helmet";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleThumbnailClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  // useEffect(() => {
  //   const canonicalLink = document.createElement("link");
  //   canonicalLink.setAttribute("rel", "canonical");
  //   canonicalLink.setAttribute(
  //     "href",
  //     "https://www.madhurimasanskar.org/about-us"
  //   );
  //   document.head.appendChild(canonicalLink);
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);
  return (
    <div className="main-container">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.madhurimasanskar.org/about-us"
        />
        <meta
          name="description"
          content="Madhurima Sewa Sanskar Sansthan empowers women, educate younger genration & provides free medical care in rural India. Dr. Swapna Verma leads the fight for a healthy & cultured society. Learn more about our mission!"
        />
        <meta
          name="keywords"
          content="Madhurima Sewa Sanskar Sansthan, Dr. Swapna Verma, Free medical camps, Women empowerment, Rural education, Mass health screening Disease prevention, Farmer innovation, Cultured youth"
        />
        <title>
          Madhurima Sewa Sanskar: Health, Education & Women Empowerment
        </title>
      </Helmet>
      <section>
        <img src={aboutImage} className="w-100" />
        <div className="main-screen-text">
          {/* <h2>परिचय</h2> */}
          <h1> परिचय </h1>
        </div>
      </section>
      <section>
        <div class="container content-container">
          <div class="row about-section">
            <div class="col-md-6 d-flex align-items-center">
              <div>
                <p>
                  देश को सशक्त और समाज को समृद्ध बनाने के अभियान में एक आहुति का
                  नाम है मधुरिमा सेवा संस्कार फाउंडेशन। वर्ष 2022 में अस्तित्व
                  में आई संस्था कर्नाटक और मध्य प्रदेश के विंध्य क्षेत्र के सतना
                  जिले में कार्यरत है। यह संस्था बीमारी मुक्त भारत और आमजन को
                  सेहतमंद बनाने के साथ महिला सशक्तिकरण, स्वरोजगार, सशक्त व
                  समृद्धि किसान और संस्कारवान नई पीढ़ी के अभियान में जुटी हुई
                  है। दूरस्थ ग्रामीण क्षेत्र और अस्पतालों तक पहुंचने में असमर्थ
                  लोगों को बेहतर स्वास्थ्य सेवाएं मुहैया कराने के लिए शिविर लगाए
                  जाते हैं। डिजिटल मास हेल्थ स्क्रीनिंग की जाती है ताकि संभावित
                  बीमारियों का पहले ही पता चल जाए और समय रहते उपचार कर बीमारी को
                  आने से पहले ही रोका जाए। किसानों को आधुनिक तकनीक से जोड़ने के
                  साथ उनकी आय में बढ़ोतरी करने के लिए नवाचार किए जा रहे हैं। नई
                  पीढ़ी को संस्कारवान बनाने मधुरिमा संस्कार पाठशाला शुरू की गई
                  है तो वही राम राज्य की स्थापना की तरफ कदम बढ़ाते हुए आपसी
                  मनमुटाव और खटास को खत्म कर रिश्तो को मजबूत बनाने के साथ उसमें
                  मिठास घोलने की मुहिम जारी है।
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <img src={leftImage} className="w-100" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container mission-section mt-5">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-5 d-flex align-items-center mission">
              <div className="mission-vision-text">
                <h3>परिकल्पना</h3>

                <p>
                  समाज का हर नागरिक स्वस्थ हो और बीमारी से मुक्ति मिल जाए तो
                  समाज और देश का समृद्धशाली होना तय है। मधुरिमा सेवा संस्कार
                  संस्थान इसी परिकल्पना को लेकर आगे बढ़ रहा है। इस दिशा में
                  लगातार प्रयास जारी है और बीमारी से मुक्ति का अभियान मूर्त रूप
                  ले रहा है। वास्तव में स्वस्थ व्यक्ति ही समृद्धशाली समाज की
                  नींव है। जीवन में खुशहाली के लिए स्वस्थ होना जरूरी है। हर
                  व्यक्ति के स्वस्थ होने पर प्रगति, समृद्धि और खुशहाली की राह
                  बड़ी आसान हो जाती है। ऐसा इसलिए क्योंकि स्वस्थ शरीर में स्वस्थ
                  मन वास करता है और व्यक्ति सकारात्मक होकर देश और समाज के लिए
                  कुछ करने का विचार करता है।
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <img src={mission} className="w-100 mission-img" />
            </div>
            <div class="col-md-1"></div>
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-5 vision-img">
              <img src={purpose} className="w-100" />
            </div>
            <div class="col-md-5 d-flex align-items-center vision-text">
              <div className="mission-vision-text">
                <h3>उद्देश्य</h3>
                <p>
                  स्वस्थ भारत, बीमारी मुक्त भारत बनाने का संकल्प है माधुरिमा
                  सेवा संस्कार संस्थान का। इसके लिए संस्थान की ओर से गांव-गांव
                  में स्वास्थ्य शिविर लगाकर निशुल्क चिकित्सकीय परामर्श दिया जाता
                  है, खून और पेशाब की जांच की जाती है। इसके अलावा निशुल्क
                  दवाइयां भी उपलब्ध कराई जाती हैं। इतना ही नहीं आने वाले समय में
                  होने वाली गंभीर बीमारी का पहले ही उपचार करने की मकसद से मास
                  हेल्थ स्क्रीनिंग की जाती है। संस्थान ने क्लीनिक ऑन व्हील एक
                  ऐसा वाहन तैयार किया है जो सुदूर इलाकों में पहुंचकर उन लोगों को
                  बेहतर स्वास्थ्य सेवाएं मुहैया कराता है जो स्वास्थ्य केंद्रों तक
                  पहुंचने में सक्षम नहीं है। लोग कैसे सेहतमंद रहे और निरोगी रहें
                  इसके लिए उन्हें परामर्श भी दिया जाता है। इस कोशिशें का मकसद
                  बीमारी से मुक्ति और समाज को स्वस्थ बनाना है।
                </p>
              </div>
            </div>

            <div class="col-md-1"></div>
          </div>

          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-5 d-flex align-items-center target-text">
              <div className="mission-vision-text">
                <h3>लक्ष्य</h3>
                <p>
                  समाज का हर व्यक्ति स्वस्थ और बीमारी मुक्त हो यही लक्ष्य है
                  मधुरिमा सेवा संस्कार संस्थान। इसी लक्ष्य की प्राप्ति के लिए
                  संस्थान एक तरफ दूरस्थ इलाके के गांव में स्वास्थ्य सुविधाएं
                  उपलब्ध करा रहा है, वही सेहतमंद रहने के टिप्स भी दे रहा है।
                  आमजन का बीमारी मुक्त और स्वस्थ होना सिर्फ समाज ही नहीं देश के
                  लिए भी जरूरी है। संस्थान की यह कोशिश समाज और देश को समृद्धि की
                  राह पर आगे बढ़ाने के लिए जारी है। एक दिन अपने लक्ष्य को पाने
                  में संस्थान जरुर सफल होगा।
                </p>
              </div>
            </div>
            <div class="col-md-5 target-img">
              <img src={target} className="w-100" />
            </div>

            <div class="col-md-1"></div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className=" about-work">
            <h2>हम कैसे काम करते हैं</h2>
          </div>
          <hr className="hr-line" />
        </div>
      </section>
      <section>
        <div>
          <img src={rightAngle} className="right-angle" />
        </div>

        <div className="we-work-section">
          <div></div>
          <div className="container ">
            <div className="row video-section">
              {/* <div className="col-md-6 d-flex align-items-center">
                <img src={weWorkVideo} className="w-100" />
              </div> */}
              <div className="col-md-6 d-flex align-items-center">
                <video
                  ref={videoRef}
                  controls
                  style={{ width: "100%", zIndex: 2 }}
                  poster={weWorkVideo}
                  onClick={handleThumbnailClick}
                >
                  <source
                    src={
                      "https://d1r7405w39vw0d.cloudfront.net/swapna-verma/madhurimaVideos/v10.mp4"
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="col-md-6 d-flex align-items-center">
                <h3 className="video-section-text">
                  मधुरिमा सेवा संस्कार संस्थान की लोगों को स्वस्थ और बीमारी
                  मुक्त बनाने की मुहिम जारी है। ग्रामीण इलाकों में स्वास्थ्य
                  शिविरों का सिलसिला चल रहा है और इन शिविरों में आने वाले मरीज
                  को चिकित्सा सुविधा मिल रही है। इन सुविधाओं ने ग्रामीणों को
                  गदगद कर दिया है क्योंकि उन्हें चिकित्सकीय परामर्श से लेकर खून-
                  पेशाब की जांच और दवाइयां निशुल्क मिल रही हैं। इन स्वास्थ्य
                  शिविरो ने समाज में व्याप्त उच्च नीच की खाई को खत्म कर दिया है, 
                  समानता का भाव बढ़ा दिया है। न तो कोई बड़ा है और न ही कोई छोटा
                  और न ही लोगों के बीच जाति वर्ग का भेद है। सभी को स्वास्थ्य
                  सुविधाएं आसानी से मिल रही हैं। लाभार्थी अपने जीवन में आ रहे
                  बदलाव से खुश है।
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={leftAngle} className="left-angle" />
        </div>
      </section>
      <section>
        <div class="container mt-5">
          <div className="justify-content-center d-flex">
            <h2 style={{ fontWeight: "bold" }}>संस्थापक</h2>
          </div>
          <div class="row justify-content-center">
            <div className="shadow-box">
              <div class="col-md-9 content-box justify-content-center">
                <p>
                  मध्य प्रदेश के विंध्य इलाके के सतना जिले में उम्मीद की किरण है
                  मधुरिमा सेवा संस्कार संस्थान। पेशे से चिकित्सक और उद्यमी डॉ
                  स्वप्ना वर्मा ने क्षेत्र को बीमारी मुक्त और सभी को स्वस्थ
                  बनाने के संकल्प के साथ वर्ष 2022 में इस संस्थान की स्थापना की
                  । इस संस्थान ने दूरस्थ इलाकों में रहने वालों तक स्वास्थ्य
                  सेवाओं की पहुंच बनाना ,महिलाओं के सशक्तिकरण ,किसानों को खेती
                  में नवाचार के लिए प्रेरित करना और नई पीढ़ी को संस्कारवान बनाने
                  को अपना मूल मंत्र बनाया है। डॉ वर्मा चिकित्सा, शिक्षा और कृषि
                  के क्षेत्र में किए गए नवाचारों के कारण ही राष्ट्रीय और
                  अंतरराष्ट्रीय मंचों पर सम्मानित हुई है। उन्हें बीमारी मुक्त
                  समाज के लिए अपनाई जा रही तकनीक ‘मास हेल्थ स्क्रीनिंग’ ( आने
                  वाले समय में होने वाली गंभीर बीमारी का पहले ही पता चल जाता है)
                  के सफल प्रयोग के लिए तत्कालीन केंद्रीय स्वास्थ्य मंत्री डॉ
                  मनसुख मांडविया ने ‘सुश्रुत अवार्ड -2024’ से दिल्ली में
                  सम्मानित किया। इसी तरह भोपाल में ‘प्राइड ऑफ मध्य प्रदेश -2024’
                  से मुख्यमंत्री डॉ मोहन यादव ने सम्मानित किया। मधुरिमा सेवा
                  संस्कार संस्थान ग्रामीण इलाकों में लगातार स्वास्थ्य शिविरों का
                  आयोजन करता है। इन शिविरों में चिकित्सकीय परामर्श से लेकर खून
                  -पेशाब की जांच और दवाइयां आदि निशुल्क उपलब्ध कराई जाती है।
                </p>
                <div className="justify-content-end d-flex">
                  <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                    - डॉ.स्वप्ना वर्मा
                  </span>
                </div>

                <div className="justify-content-end d-flex">
                  <span>संस्थापक - मधुरिमा सेवा संस्कार फाउंडेशन</span>
                </div>
              </div>
              <div class="col-md-3 image-box justify-content-center d-flex">
                <img
                  src={profileImage}
                  class="img-fluid"
                  alt="Right Side Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
    </div>
  );
};

export default About;

import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../Assets/images/logo.png";
import "./Header.scss";
const Header = () => {
  const [mobile, setMobile] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
      setMobileMenuOpen(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setIsDropdownOpen1(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  };
  console.log("logo-->", logo);
  return (
    <nav className="navbar">
      <div className="mobile-sub-text">
        <p>
          Madhurima Sewa Sanskar <br /> foundation
        </p>
      </div>
      <div
        className="menu-icon"
        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
      >
        ☰
        <img src={logo} className="mobile-view-icon" style={{ width: 70 }} />
      </div>
      <ul
        className={
          mobile && isMobileMenuOpen ? "nav-links-mobile" : "nav-links"
        }
      >
        <div className="madhurima-logo">
          <Link to="/">
            <img src={logo} alt="Madhurima Logo" style={{ width: 40 }} />
          </Link>
          {/* <div>
            <p>Madhurima Sewa Sanskar foundation</p>
          </div> */}
        </div>
        <NavLink
          to="/"
          className="home"
          activeClassName="active"
          onClick={closeMobileMenu}
        >
          <li>होम</li>
        </NavLink>
        <NavLink
          to="/about-us"
          className="About"
          activeClassName="active"
          onClick={closeMobileMenu}
        >
          <li>परिचय</li>
        </NavLink>

        <li class="nav-item dropdown show">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsDropdownOpen1(!isDropdownOpen1)}
          >
            हमारा काम
          </a>
          {isDropdownOpen1 && (
            <div className="dropdown-menu show">
              <NavLink
                to="/health-care"
                className="dropdown-item pl-3"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>स्वास्थ्य देखभाल</li>
              </NavLink>
              <NavLink
                to="/education"
                className="dropdown-item pl-3"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>शिक्षा</li>
              </NavLink>
              <NavLink
                to="/establishment-of-ram-rajya"
                className="dropdown-item pl-3"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>रामराज्य स्थापना</li>
              </NavLink>
              <NavLink
                to="/agriculture"
                className="dropdown-item pl-3"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>कृषि</li>
              </NavLink>
              <NavLink
                to="/sports"
                className="dropdown-item pl-3"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>खेल</li>
              </NavLink>
            </div>
          )}
        </li>
        <li class="nav-item dropdown show">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
          >
            मीडिया गैलरी
          </a>
          {isDropdownOpen2 && (
            <div className="dropdown-menu show">
              <NavLink
                to="/photo-gallery"
                className="Success Stories"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>फोटो गैलरी</li>
              </NavLink>
              <NavLink
                to="/video"
                className="Digital Print"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>वीडियो गैलरी</li>
              </NavLink>
            </div>
          )}
        </li>
        <li class="nav-item dropdown show">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsDropdownOpen3(!isDropdownOpen3)}
          >
            समाचार
          </a>
          {isDropdownOpen3 && (
            <div className="dropdown-menu show">
              <NavLink
                to="/news-clip"
                className="Success Stories"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>न्यूज़ क्लिप</li>
              </NavLink>
              <NavLink
                to="/digital-print"
                className="Digital Print"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                <li>डिजिटल प्रिंट</li>
              </NavLink>
            </div>
          )}
        </li>
        <NavLink
          to="./contact-us"
          className="ContactUs"
          activeClassName="active"
          onClick={closeMobileMenu}
        >
          <li>संपर्क करें</li>
        </NavLink>
      </ul>
    </nav>
  );
};

export default Header;

import axios from "axios";
const baseUrl = "https://7e3s4p6m33.execute-api.ap-south-1.amazonaws.com/Prod/";
export async function createService(endpoint, body = {}, params = {}) {
  return axios
    .post(baseUrl + endpoint, body, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`create data error is${JSON.stringify(error)}`);
      if (error.response) {
        console.log(error.response);
        console.log("server responded");
        return error.response;
      }
    });
}

export async function readService(endpoint, params = {}) {
  return axios
    .get(baseUrl + endpoint, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}
// readService();
export async function updateService(endpoint, params = {}, body) {
  return axios
    .put(baseUrl + endpoint, body, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteService(endpoint, params = {}) {
  return axios
    .delete(baseUrl + endpoint, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

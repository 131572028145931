import React from "react";
import "./Footer.scss";
import footerLogo from "../../Assets/images/footer/footer-logo.webp";
import footerFacebook from "../../Assets/images/footer/footer-facebook.webp";
import footerInstagram from "../../Assets/images/footer/footer-instagram.webp";
import footerYoutube from "../../Assets/images/footer/footer-youtube.webp";
import footerTwiterx from "../../Assets/images/footer/footer-twiterx.webp";
import footerLocation from "../../Assets/images/footer/footer-location.webp";
import footerPhone from "../../Assets/images/footer/footer-phone.webp";
import footerEmail from "../../Assets/images/footer/footer-email.webp";
import footerBg from "../../Assets/images/footer/footer-bg.webp";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="logo-section">
              <Link to="/">
                <img src={footerLogo} alt="Logo" className="footer-logo" />
              </Link>
              <h2>
                Madhurima Sewa <br /> Sanskar foundation
              </h2>
              <div className="row">
                <div className="social-icon-section">
                  <div className=" social-icon">
                    <a
                      href="https://www.facebook.com/madhurimasewa/"
                      target="_blank"
                    >
                      <img
                        src={footerFacebook}
                        alt="Facebook"
                        className="social-icon"
                      />
                    </a>
                  </div>
                  <div className=" social-icon">
                    <a
                      href="https://www.instagram.com/madhurimasewa?igsh=dzg1MTh5cmUyamRl"
                      target="_blank"
                    >
                      <img
                        src={footerInstagram}
                        alt="Instagram"
                        className="social-icon"
                      />
                    </a>
                  </div>
                  <div className=" social-icon">
                    <a
                      href="https://www.youtube.com/@madhurimasewa"
                      target="_blank"
                    >
                      <img
                        src={footerYoutube}
                        alt="Youtube"
                        className="social-icon"
                      />
                    </a>
                  </div>
                  <div className=" social-icon">
                    <a
                      href="https://x.com/i/flow/login?redirect_after_login=%2Fmadhurimasewa"
                      target="_blank"
                    >
                      <img
                        src={footerTwiterx}
                        alt="TwiterX"
                        className="social-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 justify-content-end d-flex">
            <div className="contact-info">
              <h2>संपर्क</h2>
              <p className="mt-3">
                <img src={footerLocation} alt="Location" /> Muktiyarganj, satna,
                Madhyapradesh
              </p>
              <p className="mt-3">
                <img src={footerPhone} alt="Phone" /> 080 2679 1234
              </p>
              <p className="mt-3">
                <img src={footerEmail} alt="Email" />{" "}
                <a href="mailto:contact@madhurimasanskar.org" target="_blank" >
                  contact@madhurimasanskar.org
                </a>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div>
              <h2 className="footer-left-content">त्वरित सम्पक</h2>
              <div className="quick-links ">
                <ul>
                  <li>
                    <a>
                      <NavLink to="/" activeClassName="active">
                        होम
                      </NavLink>
                    </a>
                  </li>
                  <li>
                    <NavLink to="/about-us">परिचय</NavLink>
                  </li>
                  <li>
                    <NavLink to="/health-care">हमारा काम</NavLink>
                  </li>
                </ul>
                <ul>
                  <li>
                    <NavLink to="/photo-gallery">मीडिया गैलरी</NavLink>
                  </li>
                  <li>
                    <NavLink to="/news-clip">समाचार</NavLink>
                  </li>
                  <li>
                    <NavLink to="./contact-us">संपर्क करें</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={footerBg} className="w-100" />
      <div className="silhouette-section"></div>
    </footer>
  );
};

export default Footer;
